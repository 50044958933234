/*Screen width*/
.number-input--prefix {
  left: 2rem;
  position: relative;
}
.number-input--suffix {
  padding-right: 1rem;
}
.number-input .rtls-text-field {
  height: 2.5rem;
  padding-right: 0.4rem;
  background-image: none !important;
}
.number-input .rtls-text-field--small {
  width: 4rem;
}
.number-input .rtls-text-field--medium {
  width: 6rem;
}
.number-input .rtls-text-field--large {
  width: 8rem;
}
.number-input .rtls-text-field.readonly {
  display: block;
}
.number-input .rtls-text-field:focus {
  background-image: none !important;
  outline: none !important;
}
.number-input .rtls-text-field.validation-error {
  color: #c13939;
  border: 0.1rem solid #c13939;
  border-radius: 0.5rem;
}
.number-input .rtls-text-field.validation-error:focus {
  border: 0.2rem solid #c13939 !important;
}