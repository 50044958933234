/*Screen width*/
.promotion-rag-colours {
  display: flex;
}
.promotion-rag-colours__colour {
  padding-right: 1rem;
}
.promotion-rag-colours__total {
  font-size: 1.2rem;
  font-weight: 600;
}