@charset "UTF-8";
/*Screen width*/
.simple-list {
  list-style-type: none;
  padding: 0;
}
.simple-list__item span:not(:last-child):after {
  content: "—";
  margin: 0 0.3rem;
}
.simple-list__more-items {
  color: #2f477c;
}