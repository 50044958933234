/*Screen width*/
.scope {
  padding: 0 1rem;
}
.scope__group-of-products {
  display: flex;
}
.scope__product-description {
  padding-bottom: 0.5rem;
}
.scope__products {
  padding: 0;
}
.scope__products li {
  display: flex;
}
.scope__products li:first-child {
  border-top: 0.1rem solid #c2c2c2;
}
.scope__products li span {
  padding: 0.5rem 0;
}
.scope__value, .scope__product-value {
  margin-left: auto;
  width: 3rem;
  font-weight: 600;
}