/*Screen width*/
.supplier-commitment-viewer-wrapper {
  display: contents;
  z-index: 102;
}

.supplier-commitment-viewer {
  display: contents;
  position: relative;
}
.supplier-commitment-viewer__grid-cell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.6rem 1rem;
  background-color: #ffffff;
  border-top: #e7e7e7 0.1rem solid;
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.2rem;
  color: #37424a;
  letter-spacing: 0;
  height: 100%;
}
.supplier-commitment-viewer__grid-cell > div {
  display: flex;
}
.supplier-commitment-viewer__grid-cell:hover {
  cursor: pointer;
}
.supplier-commitment-viewer__grid-cell::v-deep .theme--light.v-label {
  color: #37424a;
}
.supplier-commitment-viewer__grid-cell::v-deep .measurement .v-input--selection-controls__input {
  margin-right: 0;
  width: 1.8rem;
}
.supplier-commitment-viewer__grid-cell::v-deep .measurement .v-input--selection-controls__input .v-icon.v-icon {
  font-size: 1.4rem;
}
.supplier-commitment-viewer > div:first-child .supplier-commitment-viewer__grid-cell {
  font-weight: 600;
}
.supplier-commitment-viewer__expanded-area {
  grid-column: 1/span 8;
  background-color: #ffffff;
  padding: 0.6rem 1rem;
}

.supplier-commitments-viewer__header + .supplier-commitment-viewer-wrapper .supplier-commitment-viewer__grid-cell,
.supplier-commitments-viewer__historical-title + .supplier-commitment-viewer-wrapper .supplier-commitment-viewer__grid-cell {
  border-top-color: #c2c2c2;
}