/*Screen width*/
.workflow-task-alert {
  border: 0.1rem solid;
  box-sizing: border-box;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  background-color: #ffffff;
  display: grid;
  grid-auto-flow: column;
}
.workflow-task-alert--critical {
  border-color: #831d1d;
}
.workflow-task-alert--high {
  border-color: #d7534e;
}
.workflow-task-alert--medium {
  border-color: #6d7278;
}
.workflow-task-alert--low {
  border-color: #6d7278;
}
.workflow-task-alert--one-column {
  grid-template-columns: auto;
}
.workflow-task-alert--two-column {
  grid-template-columns: auto 2.5rem;
}
.workflow-task-alert__body {
  display: grid;
  padding: 0.5rem;
}
.workflow-task-alert__arrow {
  display: flex;
  align-items: center;
  color: #ffffff;
}
.workflow-task-alert__arrow--critical {
  background-color: #831d1d;
}
.workflow-task-alert__arrow--high {
  background-color: #d7534e;
}
.workflow-task-alert__arrow--medium {
  background-color: #6d7278;
}
.workflow-task-alert__arrow--low {
  background-color: #6d7278;
}
.workflow-task-alert__header {
  display: grid;
  grid-template-columns: auto 4.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.workflow-task-alert__header--tag {
  text-align: center;
}
.workflow-task-alert__header--critical {
  color: #831d1d;
}
.workflow-task-alert__header--high {
  color: #d7534e;
}
.workflow-task-alert__header--medium {
  color: #6d7278;
}
.workflow-task-alert__header--low {
  color: #6d7278;
}
.workflow-task-alert__message {
  font-size: 1.2rem;
}
.workflow-task-alert__message--critical {
  color: #831d1d;
}
.workflow-task-alert__message--high {
  color: #d7534e;
}
.workflow-task-alert__message--medium {
  color: #6d7278;
}
.workflow-task-alert__message--low {
  color: #6d7278;
}