.page {
  height: 100%;
  padding: 2rem 1rem;
}

::v-deep .v-tabs.promo-tabs.theme--light {
  height: 100%;
  display: flex;
  flex-direction: column;
}
::v-deep .v-window.v-item-group.theme--light.v-tabs-items {
  flex: 1 0 0;
}
::v-deep .v-window__container {
  height: 100%;
}
::v-deep .v-window-item.tab {
  height: 100%;
}
::v-deep .v-window-item.tab.past-promotions-tab {
  border-left: 0.3rem solid #aecadb;
}
::v-deep .v-window-item.tab.parking-lot-tab {
  border-left: 0.3rem solid #4ca183;
}
::v-deep .v-window-item.tab.sub-campaign-allocation-tab {
  border-left: 0.3rem solid #94ab28;
}
::v-deep .promo-tabs .promo-tabs__tab.parking-lot-tab {
  border-left-color: #4ca183;
}
::v-deep .promo-tabs .promo-tabs__tab.sub-campaign-allocation-tab {
  border-left-color: #94ab28;
}
::v-deep .ag-checkbox-center {
  display: flex;
  align-content: center;
  justify-content: center;
}