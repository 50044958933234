/*Screen width*/
.reward-requirement-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}
.reward-requirement-option__label {
  color: #37424a;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.4rem;
  white-space: nowrap;
}
.reward-requirement-option__number-input {
  margin-left: 0.6rem;
  width: 4rem;
  margin-right: 0.3rem;
}
.reward-requirement-option__remove-option-item {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.reward-requirement-option__remove-option-label {
  margin-left: 0.5rem;
}
.reward-requirement-option__additional-reward {
  margin-left: 0.6rem;
}
.reward-requirement-option__additional-reward ::v-deep .v-input__slot,
.reward-requirement-option__additional-reward ::v-deep .v-select__slot {
  height: 2.1rem !important;
}
.reward-requirement-option .icon-container {
  min-width: 2rem;
}
.reward-requirement-option .icon-container .v-icon {
  display: none;
  color: #2f477c;
}
.reward-requirement-option .icon-container .disabled {
  opacity: 0.5;
}
.reward-requirement-option:hover {
  background-color: #e6f5fc;
}
.reward-requirement-option:hover .icon-container .v-icon {
  display: block;
}

.v-list-item {
  font-size: 1.2rem;
  min-height: 2rem;
  color: #37424a;
  letter-spacing: 0;
  line-height: 1.4rem;
  padding-left: 0.8rem;
}