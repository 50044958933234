.summary-kpis-row {
  display: flex;
  text-align: right;
}
.summary-kpis-row__header {
  width: 20%;
}
.summary-kpis-row__header--bold {
  width: 20%;
  font-weight: 600;
}
.summary-kpis-row__data {
  width: 40%;
  font-weight: 600;
}