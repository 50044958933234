.promotion-forecasting button {
  margin: 0.5rem 0.5rem;
}
.promotion-forecasting--column-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.promotion-forecasting .forecast-date {
  width: 10rem;
  margin-right: 2.5rem;
}
.promotion-forecasting .forecast-date span {
  display: flex;
  flex-direction: column;
}
.promotion-forecasting .v-divider:not(.metrics__divider) {
  margin: 0.5rem 0;
}