/*Screen width*/
.review-changes__description {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  box-shadow: 0 6px 6px -6px #000000;
  margin-bottom: 1rem;
  background-color: #ffffff;
}
.review-changes__grids {
  padding: 1rem;
  max-height: 70rem;
  overflow-y: scroll;
  background-color: #eff5f6;
}
.review-changes__banner {
  height: 6rem;
  margin: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f9e9e9;
  border: 0.1rem solid #c11512;
}
.review-changes__banner .lock-icon {
  font-size: 2.8rem;
  margin-right: 0.5rem;
}
.review-changes__banner .lock-icon svg path,
.review-changes__banner .lock-icon svg text {
  fill: #c11512;
}
.review-changes__banner .lock-reason {
  font-size: 1.4rem;
  color: #000000;
}
.review-changes__button {
  margin: 1rem;
  height: 3.4rem !important;
  font-size: 1.6rem !important;
  background-color: #c11512 !important;
  color: #ffffff;
}
.review-changes__title {
  font-family: "Source Sans Pro";
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  color: #37424a;
  margin-bottom: 1rem;
}
.review-changes__overlay {
  z-index: 99 !important;
  grid-column-start: 1;
  grid-column-end: -1;
}

.dialog-card {
  border-left: 0.4rem solid #2f477c;
  border-radius: 0;
  padding: 0;
  min-width: 144rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eff5f6;
}
.dialog-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px solid #e7e7e7;
  padding: 1rem;
  background-color: #ffffff;
}
.dialog-card__title {
  font-family: "Source Sans Pro";
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  color: #37424a;
}
.dialog-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 80vh;
  overflow: auto;
}
.dialog-card__footer {
  height: 5.5rem;
  padding: 1rem;
  margin-top: 1rem;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  box-shadow: 0 -6px 6px -6px #000000;
}
.dialog-card .buttons {
  width: 100%;
  text-align: right;
  float: right;
}
.dialog-card .buttons .v-btn {
  margin: 0 0.5rem;
}
.dialog-card .buttons .v-btn--disabled {
  color: #ffffff !important;
  border: 0.1rem solid #9b9b9bad !important;
  background-color: #9b9b9bad !important;
}
.dialog-card .buttons .tooltip-wrapper {
  height: fit-content;
  width: fit-content;
  display: inline-block;
  cursor: default;
}
.dialog-card .buttons .review-changes-btn--child {
  color: #ffffff;
  border: 0.1rem solid #51b4c1;
  background-color: #51b4c1;
}
.dialog-card .buttons .review-changes-btn--parent {
  color: #ffffff;
  border: 0.1rem solid #ab4bbb;
  background-color: #ab4bbb;
}
.dialog-card .buttons .review-changes-btn--apply {
  color: #ffffff;
  border: 0.1rem solid #2f477c;
  background-color: #2f477c;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}