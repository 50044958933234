/*Screen width*/
.vuex-currency-input {
  display: flex;
  flex-direction: row;
}
.vuex-currency-input__currency-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  height: 2.9rem;
  padding: 0 0.5rem;
  color: #ffffff;
  background-color: #2f477c;
}
.vuex-currency-input__currency-symbol--disabled {
  background-color: #9b9b9b;
  font-weight: normal;
}
.vuex-currency-input .error--text {
  font-weight: normal;
}