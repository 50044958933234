/*Screen width*/
.create-promotion-container__create-btn {
  position: absolute;
  bottom: 1.5rem;
  left: 2rem;
  font-size: 1.2rem;
}

.promotion-dialog-container {
  width: 95vw;
}
.promotion-dialog-container::v-deep .promotion-viewer {
  display: grid;
  grid-template-columns: 8rem repeat(3, auto) fit-content(38rem) repeat(4, auto) repeat(2, calc(11rem));
  position: relative;
}
.promotion-dialog-container::v-deep .promotion-viewer__grid-cell--main-content {
  padding-top: 0;
}
.promotion-dialog-container::v-deep .promotion-viewer--first-item {
  margin-left: 0;
}