/*Screen width*/
.candidates-list {
  display: grid;
  height: 100%;
}
.candidates-list__drop-area {
  height: 100%;
}
.candidates-list__candidate-hover:hover {
  cursor: pointer;
}
.candidates-list #noCandidatesPlaceholder {
  color: #9b9b9b;
}