/*Screen width*/
.alert-count-badge {
  width: 2.3rem;
  border-radius: 2rem;
  margin-bottom: 0.2rem;
  font-weight: normal;
  font-size: 1.2rem;
  text-align: center;
  color: #ffffff;
}
.alert-count-badge--critical {
  background: #831d1d;
}
.alert-count-badge--high {
  background: #d7534e;
}
.alert-count-badge--medium {
  background: #6d7278;
}
.alert-count-badge--low {
  background: #6d7278;
}