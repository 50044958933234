/*Screen width*/
.copy-promotion {
  font-size: 1.2rem;
}
.copy-promotion__description {
  padding: 1rem 0;
}
.copy-promotion__form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2rem;
}
.copy-promotion .select-resource {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.copy-promotion .select-resource__label {
  margin-right: 1rem;
  font-weight: bold;
}
.copy-promotion .select-resource__select {
  width: 40rem;
}