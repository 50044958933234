/*Screen width*/
.vuex-percentage-input {
  display: flex;
  flex-direction: row;
}
.vuex-percentage-input__percentage-symbol {
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.9rem;
  padding: 0 0.5rem;
  color: #ffffff;
  background-color: #2f477c;
}
.vuex-percentage-input__percentage-symbol--disabled {
  background-color: #9b9b9b;
  font-weight: normal;
}
.vuex-percentage-input .error--text {
  font-weight: normal;
}