/*Screen width*/
.total-spend-viewer__funded-promotions-list-wrapper {
  display: contents;
}
.total-spend-viewer__icons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.9rem 1.5rem;
  background-color: #ffffff;
}
.total-spend-viewer__filler {
  grid-column-start: 1;
  grid-column-end: -1;
  margin: 1rem 0;
  height: 1rem;
}
.total-spend-viewer__filler--grey {
  background-color: #f2f3f4;
}
.total-spend-viewer__filler--reseted-margin {
  margin-top: 0;
  background-color: #f2f3f4;
}
.total-spend-viewer__new-card {
  grid-column-start: 1;
  grid-column-end: -1;
  border-top: #e7e7e7 0.1rem solid;
  background-color: #ffffff;
}
.total-spend-viewer__buttons {
  grid-column-start: 1;
  grid-column-end: -1;
  background: #ffffff;
  padding-bottom: 1rem;
  text-align: right;
  padding-right: 2rem;
}
.total-spend-viewer__buttons--cancel-button {
  color: #2f477c;
  font-weight: 600;
}
.total-spend-viewer__create-button {
  margin-left: 1rem;
  font-size: 1.2rem;
}
.total-spend-viewer__bottom {
  grid-column-start: 1;
  grid-column-end: -1;
  margin-top: 1rem;
}
.total-spend-viewer__grid {
  display: grid;
  grid-template-columns: 20rem 20rem auto 15rem 10rem 10rem 10rem 20rem 5rem 5rem 5rem;
}
.total-spend-viewer__header {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #37424a;
  background-color: #ffffff;
  padding-left: 1rem;
  padding-bottom: 0.6rem;
  border-bottom: #c2c2c2 0.1rem solid;
}
.total-spend-viewer__header.last-column {
  grid-column-end: span 4;
  padding-right: 1rem;
}
.total-spend-viewer__header.select-heading {
  grid-column-end: span 3;
  text-align: center;
  padding-right: 3rem;
}
.total-spend-viewer__header.bold {
  font-weight: 600;
}
.total-spend-viewer__historical-title {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 2rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.4rem;
  background-color: #ffffff;
}
.total-spend-viewer__target {
  grid-column-start: 1;
  grid-column-end: -1;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit {
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.2rem;
  color: #37424a;
  letter-spacing: 0;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .store-groups {
  display: flex;
  grid-column: 1/span 7;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .promo-resources {
  grid-column: span 4;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .border-right {
  border-right: #c2c2c2 0.1rem solid;
  text-align: right;
  padding-right: 1rem;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .align-right {
  text-align: right;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .bold {
  font-weight: 600;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .icon-checkboxes-list-container__control {
  display: flex;
  padding: 0;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .rtls-select-container {
  display: flex;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit .rtls-select-container__label {
  margin-right: 1rem;
  line-height: 4rem;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0rem 1rem;
  background-color: #ffffff;
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.2rem;
  color: #37424a;
  letter-spacing: 0;
  height: 100%;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.category .v-select.v-select--chips.v-select--chips--small .v-chip {
  margin: 0.2rem 0.4rem;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.category .v-select.v-select--chips.v-select--chips--small .v-select__selections {
  min-height: 2.8rem;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.store-groups {
  padding-top: 2rem;
  grid-column: 1/span 7;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.allocation-checkbox {
  display: flex;
  justify-content: center;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.promo-resources {
  padding-top: 2rem;
  grid-column: span 4;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.border-right {
  border-right: #c2c2c2 0.1rem solid;
  text-align: right;
  padding-right: 1rem;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.align-right {
  text-align: right;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell.bold {
  font-weight: 600;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell .rtls-select-container {
  display: flex;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell .rtls-select-container__label {
  margin-right: 1rem;
  font-weight: 400;
  line-height: 3rem;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell .rtls-select-container .v-input--is-disabled .v-input__control > .v-input__slot:before {
  border-image: none !important;
  border: none !important;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell .rtls-select-container .v-input--is-disabled .v-input__append-inner {
  display: none;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__grid-cell .icon-checkboxes-list-container__control {
  display: flex;
  padding: 0;
}
.total-spend-viewer ::v-deep .v-input--is-disabled .v-input__control > .v-input__slot:before {
  border-image: none !important;
  border: none !important;
}
.total-spend-viewer ::v-deep .v-input--is-disabled .v-input__append-inner {
  display: none;
}
.total-spend-viewer ::v-deep .vuex-form-viewer__edit form > div {
  margin: 0.3rem 0 !important;
}
.total-spend-viewer ::v-deep .vuex-from-grid > div {
  background: #fff;
  padding: 0 1rem 1.4rem;
  height: 60%;
  margin-top: 1rem;
}
.total-spend-viewer ::v-deep .icon-checkboxes-list-container {
  padding: 0;
  display: flex;
  position: relative;
}
.total-spend-viewer ::v-deep .icon-checkboxes-list-container .v-input--checkbox.error--text {
  padding-bottom: 1rem;
}
.total-spend-viewer ::v-deep .icon-checkboxes-list-container .v-messages.error--text {
  position: absolute;
  top: 3.5rem;
  left: 0.3rem;
}