/*Screen width*/
.allocate-promotion {
  font-size: 1.2rem;
}
.allocate-promotion__description {
  padding: 1rem 0;
}

.in-progress__message {
  font-size: 1.3rem;
  color: #37424a;
  margin: 1rem;
}
.in-progress__message .spinner {
  width: 1.4rem;
  height: 1.4rem;
}

::v-deep .v-text-field__slot label {
  padding-left: 1rem;
}