/*Screen width*/
.vuex-form-viewer {
  display: contents;
  z-index: 102;
}
.vuex-form-viewer__read {
  display: contents;
  position: relative;
}
.vuex-form-viewer__edit {
  display: contents;
}

.vuex-from-grid {
  display: contents;
}

.vuex-form-viewer__grid-cell--expandable:hover {
  cursor: pointer;
}