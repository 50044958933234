/*Screen width*/
.promotion-rag-colour__colour {
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 5rem;
  margin-top: 0.3rem;
  background: #aecadb;
}
.promotion-rag-colour__colour--1 {
  background: #d7534e;
}
.promotion-rag-colour__colour--2 {
  background: #ea9b66;
}
.promotion-rag-colour__colour--3 {
  background: #93ab26;
}
.promotion-rag-colour__colour--4 {
  background: #3d8113;
}
.promotion-rag-colour__colour--5 {
  background: #c738b4;
}
.promotion-rag-colour__border {
  background: #fff;
  border: 0.2rem solid;
}
.promotion-rag-colour__border--1 {
  border-color: #d7534e;
}
.promotion-rag-colour__border--2 {
  border-color: #ea9b66;
}
.promotion-rag-colour__border--3 {
  border-color: #93ab26;
}
.promotion-rag-colour__border--4 {
  border-color: #3d8113;
}
.promotion-rag-colour__border--5 {
  border-color: #c738b4;
}
.promotion-rag-colour__count--vertical {
  margin-top: 0.2rem;
  text-align: center;
}
.promotion-rag-colour__count--horizontal {
  margin-top: 0.2rem;
  margin-left: 0.5rem;
}
.promotion-rag-colour .big-circle {
  height: 1.8rem;
  width: 1.8rem;
  margin-top: 0;
}