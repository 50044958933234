/*Screen width*/
.ag-checkbox-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ag-checkbox-wrapper__centered {
  justify-content: center;
}
.ag-checkbox-wrapper .ag-checkbox .v-input--selection-controls__input {
  margin: 0;
}

.simple-checkbox .v-icon {
  color: #2f477c;
  font-size: 2rem;
}
.simple-checkbox .v-input--selection-controls__ripple {
  height: 3rem;
  width: 3rem;
  top: calc(50% - 2.2rem);
}