/*Screen width*/
.candidates-list__product {
  margin: 0 1.2rem;
  height: 5rem;
}

.product {
  display: grid;
  grid-template-columns: 4rem 6rem auto 4rem;
  padding: 0.5rem;
  background-color: #eff5f6;
  border-radius: 0.3rem;
}
.product--compact {
  grid-template-columns: 4rem auto;
}
.product__inner-grid {
  display: grid;
  grid-template-columns: auto auto;
}
.product__slot {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
}
.product__slot--right {
  text-align: right;
}
.product__draggable {
  grid-row: span 2;
  grid-column: span 1;
}