/*Screen width*/
.message {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
  text-align: center;
}

.task-button {
  margin-left: 1rem;
}

.notes__input-container {
  padding: 1rem 1rem 0.5rem;
  background: #dfebee;
}
.notes__text {
  padding-right: 0.5rem;
  font-size: 1.2rem;
  word-break: break-word;
}
.notes__input ::v-deep .v-textarea {
  border-radius: 0;
  box-shadow: 0;
}
.notes__input ::v-deep .v-label {
  top: 0.5rem !important;
}
.notes__input ::v-deep .v-input__slot .v-text-field__slot textarea {
  margin: 0;
  margin-top: 0.5rem;
}

.icons-container {
  position: relative;
}

.notification-indicator {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  right: -0.1rem;
  top: -0.2rem;
  background-color: #d7534e;
  padding: 0.4rem !important;
}