/*Screen width*/
.funded-promotions-list {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.funded-promotions-list__data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.75rem;
  padding-left: 3rem;
  padding-right: 3rem;
  min-height: 3.5rem;
  background-color: #ffffff;
  border-top: #aecadb 0.2rem solid;
  border-bottom: #d3d3d3 0.1rem solid;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #4a4a4a;
  letter-spacing: 0;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.05), 0 0.2rem 0 0 rgba(0, 0, 0, 0.05);
}
.funded-promotions-list__data:hover {
  cursor: pointer;
}
.funded-promotions-list__border-colour--1 {
  border-top-color: #d7534e;
}
.funded-promotions-list__border-colour--2 {
  border-top-color: #ea9b66;
}
.funded-promotions-list__border-colour--3 {
  border-top-color: #93ab26;
}
.funded-promotions-list__border-colour--4 {
  border-top-color: #3d8113;
}
.funded-promotions-list__border-colour--5 {
  border-top-color: #c738b4;
}
.funded-promotions-list__grid-cell {
  padding-top: 0.5rem;
  display: contents;
}
.funded-promotions-list__last-column {
  flex: 1 0 0;
}