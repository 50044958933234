/*Screen width*/
.warning-text {
  color: #ff5252;
}

.information-text {
  color: #3d8113;
}

.message-title {
  font-size: 1.4rem;
}