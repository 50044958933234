/*Screen width*/
.supplier-commitments-viewer {
  background-color: #ffffff;
  padding-bottom: 2rem;
}
.supplier-commitments-viewer__toggle {
  width: 100%;
  padding: 1rem 0 1rem 1rem;
}
.supplier-commitments-viewer__specified-allocations-grid {
  display: grid;
  grid-template-columns: 20rem 20rem auto 25rem 15rem 10rem 20rem 5rem 5rem [last-column];
}
.supplier-commitments-viewer__specified-allocations-grid::v-deep .category .v-select.v-select--chips.v-select--chips--small .v-chip {
  margin: 0.2rem 0.4rem;
}
.supplier-commitments-viewer__specified-allocations-grid::v-deep .category .v-select.v-select--chips.v-select--chips--small .v-select__selections {
  min-height: 2.8rem;
}
.supplier-commitments-viewer__total-spend-grid {
  display: grid;
  grid-template-columns: 20rem 20rem auto 10rem 10rem 10rem 10rem 20rem 5rem 5rem [last-column];
}
.supplier-commitments-viewer__header {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #37424a;
  padding-left: 1rem;
  padding-bottom: 0.3rem;
}
.supplier-commitments-viewer__header.bold {
  font-weight: 600;
}
.supplier-commitments-viewer__historical-title {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 2rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.4rem;
}