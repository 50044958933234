/*Screen width*/
.promotion-forecasts {
  width: 90rem;
}
.promotion-forecasts__forecasts-block {
  font-size: 1.4rem;
  padding-bottom: 1rem;
  display: grid;
}
.promotion-forecasts__forecasts-block .border-top-blue {
  border-top: 0.1rem solid #00a0e2;
  margin-top: 0.5rem;
}
.promotion-forecasts__forecasts-block .border-bottom-grey {
  border-bottom: 0.1rem solid #9b9b9b;
  color: #9b9b9b;
}
.promotion-forecasts__forecasts-block .border-top-grey {
  border-top: 0.1rem solid #9b9b9b;
}
.promotion-forecasts__forecasts-block .background-blue {
  background-color: #eff5f6;
}
.promotion-forecasts__forecasts-block .border-right {
  border-right: 0.1rem solid #9b9b9b;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
}
.promotion-forecasts__forecasts-block .cell {
  display: flex;
  justify-content: flex-end;
}
.promotion-forecasts__forecasts-block .positive {
  color: #3d8113;
}
.promotion-forecasts__forecasts-block .negative {
  color: #ff5252;
}
.promotion-forecasts__forecasts-block .selected-hierarchy {
  font-weight: 700;
}
.promotion-forecasts__forecasts-block--name {
  font-size: 1.8rem;
}
.promotion-forecasts__forecasts-block--current {
  color: #9b9b9b;
}
.promotion-forecasts__forecasts-block .highlighted, .promotion-forecasts__forecasts-block--target {
  color: #00a0e2;
}
.promotion-forecasts__forecasts-block--header, .promotion-forecasts__forecasts-block--wrapper, .promotion-forecasts__forecasts-block--name, .promotion-forecasts__forecasts-block--productTarget, .promotion-forecasts__forecasts-block--delta, .promotion-forecasts__forecasts-block--forecasts, .promotion-forecasts__forecasts-block--current, .promotion-forecasts__forecasts-block--target {
  display: contents;
}
.promotion-forecasts__forecasts-block--forecasts {
  font-weight: 600;
}