/*Screen width*/
.client-systems-testing {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.client-systems-testing__switch, .client-systems-testing__buttons, .client-systems-testing__headers {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-weight: 600;
  background-color: white;
}
.client-systems-testing__switch--test, .client-systems-testing__buttons--test, .client-systems-testing__headers--test {
  padding-right: 1rem;
}
.client-systems-testing__headers > * {
  width: 50%;
}
.client-systems-testing__buttons {
  border-top: 1px rgba(215, 221, 231, 0.5) solid;
}
.client-systems-testing__buttons--divider {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.client-systems-testing__buttons--clear {
  margin-left: auto;
}
.client-systems-testing__editors {
  display: flex;
  height: 100%;
}
.client-systems-testing ::v-deep .jsoneditor-menu {
  background-color: #2f477c;
  border: unset;
}
.client-systems-testing ::v-deep .jsoneditor {
  border: unset;
  height: 100%;
  background-color: white;
}
.client-systems-testing ::v-deep .jsoneditor-mode-view {
  border-left: 2px #2f477c solid;
  height: 100%;
  background-color: white;
}
.client-systems-testing ::v-deep .jsoneditor-navigation-bar,
.client-systems-testing ::v-deep .jsoneditor-poweredBy,
.client-systems-testing ::v-deep .jsoneditor-repair,
.client-systems-testing ::v-deep .jsoneditor-sort,
.client-systems-testing ::v-deep .jsoneditor-statusbar {
  display: none;
}
.client-systems-testing ::v-deep .max-btn {
  display: unset;
}
.client-systems-testing ::v-deep .jsoneditor-value,
.client-systems-testing ::v-deep .jsoneditor-field,
.client-systems-testing ::v-deep .jsoneditor-separator {
  line-height: 2rem;
}