.wrapper {
  display: grid;
  grid-template-columns: minmax(15rem, 30rem) minmax(15rem, 1fr);
}

.details {
  width: 75%;
  display: flex;
  align-items: baseline;
  margin-left: 2rem;
  margin-top: 1rem;
}
.details__label {
  font-size: 1.2rem;
  text-align: right;
  padding: 0;
}
.details__content {
  margin-left: 2rem;
  font-size: 1.2rem;
  padding: 0;
}

.spacer {
  margin-left: 9rem;
}