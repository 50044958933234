/*Screen width*/
.summary-header {
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding: 0 0 0 1rem;
  flex-direction: column;
  justify-content: space-between;
}
.summary-header__title {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.summary-header__label {
  white-space: normal;
  min-height: 3.4rem;
}
.summary-header__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #d3dee7;
  padding: 0.5rem;
}
.summary-header .expand-btn {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.5rem;
}