/*Screen width*/
.page-layout {
  padding-left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.page-layout__labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.page-layout__labels-label {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  padding: 0.5rem 0;
}

.page-layout-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #e7e7e7;
}
.page-layout-wrapper__selector {
  height: 100%;
  width: 100%;
  display: grid;
}
.page-layout-wrapper__carousel-button {
  position: absolute;
  top: calc(50% - 1rem);
  z-index: 2;
}
.page-layout-wrapper__carousel-button--next {
  right: 1rem;
}
.page-layout-wrapper__carousel-button--prev {
  left: 1rem;
}
.page-layout-wrapper__panel {
  position: absolute;
  width: 32rem;
  height: 12rem;
  top: calc(50% - 6rem);
  left: calc(50% - 16rem);
  background: rgba(255, 255, 255, 0.8);
}
.page-layout-wrapper__panel--description {
  text-align: center;
  font-size: 1.2rem;
}
.page-layout-wrapper__panel--select-template {
  display: flex;
  justify-content: center;
}
.page-layout-wrapper__panel--select-button {
  background: transparent;
  border: 0.0625rem solid #2f477c;
  color: #2f477c;
  width: 7rem;
  margin-left: calc(50% - 3.5rem);
}
.page-layout-wrapper__panel--select-button:hover {
  color: #ffffff;
  background: #2f477c;
}

.nav-link {
  color: #2f477c;
}