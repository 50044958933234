/*Screen width*/
.rtls-expansion-panel .rtls-expansion-panel-header {
  padding: 0;
}
.rtls-expansion-panel .rtls-expansion-panel-header > * {
  flex-grow: 0;
}
.rtls-expansion-panel .rtls-expansion-panel-header__label {
  color: #2f477c;
  font-size: 1.2rem;
  letter-spacing: 0;
  padding-right: 1rem;
}

.offer-restrictions {
  display: flex;
  flex-direction: row;
}
.offer-restrictions__number-narrow {
  max-width: 5rem;
  padding-left: 1rem;
}
.offer-restrictions__number-wide {
  max-width: 13rem;
}
.offer-restrictions__group {
  padding-right: 1.7rem;
  padding-left: 0.3rem;
}
.offer-restrictions__group--right {
  padding-left: 2rem;
  border-left: 0.1rem solid #dddddd;
}
.offer-restrictions__item {
  padding-bottom: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.offer-restrictions__item--label {
  padding-bottom: 0.5rem;
}
.offer-restrictions__item .rtls-select-container {
  max-width: 15rem;
}
.offer-restrictions__stretch-offer p {
  padding: 0.3rem 0;
}
.offer-restrictions__stretch-offer ::v-deep .v-input--selection-controls {
  margin-top: 0;
}
.offer-restrictions__stretch-offer ::v-deep legend.v-label {
  padding-right: 0;
}
.offer-restrictions__all-variants-flag {
  padding-top: 0.6rem;
  padding-bottom: 1rem;
}
.offer-restrictions__all-variants-flag > div {
  margin-top: 0.5rem;
  padding-left: 0.3rem;
}
.offer-restrictions__commercial-field div.offer-restrictions__item--label {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
}
.offer-restrictions__commercial-field ::v-deep .v-input--selection-controls {
  margin-top: 0;
}

::v-deep .v-expansion-panel-header__icon .v-icon {
  font-size: 2.8rem;
  color: #2f477c !important;
}
::v-deep .v-expansion-panel--active .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  color: #00a0e2 !important;
}
::v-deep .v-expansion-panel--active .v-expansion-panel-header {
  min-height: 4rem;
}
::v-deep .v-expansion-panel-header {
  min-height: 4rem;
}