/*Screen width*/
.create-promotion {
  background-color: #ffffff;
  margin-left: 0px !important;
  width: 100%;
  position: relative;
  display: inline-flex;
  bottom: 0.5rem;
}
.create-promotion--first-item {
  padding-left: 0.6rem;
}
.create-promotion--promotion-name {
  width: 20rem;
}
.create-promotion--menu__list {
  background: #f1f5f6;
  padding: 0;
}
.create-promotion--menu__icon {
  padding: 0 0.5rem;
}
.create-promotion--prefix {
  display: flex;
  align-items: center;
}
.create-promotion--prefix div {
  justify-content: flex-start;
  color: #39477b;
  font-weight: 700;
  font-size: 1.2rem;
}
.create-promotion--create-btn {
  font-size: 1.2rem;
  position: relative;
  left: 2rem;
}

.v-list-item {
  padding: 0rem;
  min-height: 3.2rem;
  min-width: 14rem;
  border: none;
  text-align: center;
}
.v-list-item:hover {
  background: #e1f1fb;
}
.v-list-item div,
.v-list-item ::v-deep div {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  color: #39477b;
  font-weight: 700;
  font-size: 1.2rem;
}
.v-list-item div:hover,
.v-list-item ::v-deep div:hover {
  background: none;
}
.v-list-item div:hover:before,
.v-list-item ::v-deep div:hover:before {
  opacity: 0;
}

.v-menu__content {
  z-index: 102 !important;
  margin-top: 0.5rem;
}