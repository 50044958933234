/*Screen width*/
.document-scroller {
  display: flex;
  height: 10rem;
  margin-top: 1rem;
}

.document-navigator {
  display: grid;
  width: 100%;
  color: #9b9b9b;
}
.document-navigator__arrow {
  align-self: center;
}

.document {
  border-left: 0.1rem solid #e7e7e7;
}
.document__border--right {
  border-right: 0.1rem solid #e7e7e7;
}

.document-performance {
  align-self: center;
  text-align: right;
  margin-right: 2rem;
}

.selected-document {
  border-bottom: 3px solid #2f477c;
  color: #37424a;
  max-width: 50rem;
}

.truncate-text {
  font-weight: normal;
}