/*Screen width*/
.product-changes-viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
}
.product-changes-viewer__actions {
  width: 100%;
  background-color: #ffffff;
}
.product-changes-viewer__actions--button {
  float: right;
  margin: 0.5rem;
  color: #2f477c;
}

.product-changes-viewer::v-deep .ag-header-row-column-group {
  top: 0.1rem !important;
}
.product-changes-viewer::v-deep .ag-header {
  border-bottom: none !important;
}
.product-changes-viewer::v-deep .ag-header-row {
  border-bottom: solid 0.3rem #eff5f6 !important;
}
.product-changes-viewer::v-deep .parent-child--removed {
  text-decoration: line-through;
}