/*Screen width*/
.icon-btn {
  outline: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.icon-btn--invert-order {
  flex-direction: row-reverse;
}
.icon-btn--disabled {
  cursor: not-allowed;
}
.icon-btn--middle {
  vertical-align: middle;
}
.icon-btn--top {
  vertical-align: top;
}
.icon-btn--bottom {
  vertical-align: bottom;
}
.icon-btn--bg::v-deep .icon-btn__icon {
  background-color: #2f477c;
  border-radius: 0.3rem;
  color: #ffffff;
}
.icon-btn__text {
  color: #2f477c;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

::v-deep .icon-btn__icon {
  color: #2f477c;
  height: 1.8rem;
  width: 1.8rem;
}