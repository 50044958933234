/*Screen width*/
.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ffffff;
  z-index: 100;
  padding: 7.6rem 0 5.2rem 0;
  transition: all 0.5s;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  overflow-y: auto;
}
.main-sidebar--collapsed {
  width: 3rem;
}
.main-sidebar--expanded {
  width: 25rem;
}
.main-sidebar__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 3rem;
}
.main-sidebar__nav .alert-list-counts {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.main-sidebar__expanded-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  padding-left: 1.4rem;
  border-right: 1px solid rgba(215, 221, 231, 0.5);
}
.main-sidebar__workflow-task-alert-list {
  padding-bottom: 1rem;
}
.main-sidebar__notifications-list-header {
  color: #37424a;
  font-size: 1.2rem;
  font-weight: 600;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.main-sidebar__notifications-list {
  padding-bottom: 4rem;
}
.main-sidebar__notifications-list ::v-deep .notification {
  width: 20rem;
  margin: 0 !important;
  padding: 0;
}