/*Screen width*/
.small-btn.v-btn.v-size--default {
  height: 2rem !important;
}

.action-date-time {
  margin-left: 0.5rem;
  color: #000000;
}
.action-date-time__label {
  font-weight: bold;
}