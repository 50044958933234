/*Screen width*/
.detailed-provisions-grid {
  width: 87rem;
}
.detailed-provisions-grid__headers {
  display: grid;
  grid-template-columns: 10rem 75rem;
  border-bottom: 0.1rem solid #e7e7e7;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
}
.detailed-provisions-grid__rows .row {
  display: grid;
  grid-template-columns: 10rem 55rem 20rem;
  margin: 0;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-top: 0.5rem;
}
.detailed-provisions-grid__rows .row--highlighted {
  background: #cfe4f1;
}
.detailed-provisions-grid__rows .row__channel-cell {
  display: flex;
  align-self: center;
}
.detailed-provisions-grid__rows .row__delete-cell {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding-right: 1rem;
}
.detailed-provisions-grid__rows .row__provisions-cell::v-deep .v-autocomplete .v-select__slot > input {
  padding-left: 0.5rem;
}