/*Screen width*/
.ag-header-with-spinner {
  padding-left: 0.7rem;
}
.ag-header-with-spinner__spinner {
  font-size: 1.2rem;
}
.ag-header-with-spinner .alert-banner {
  margin: 0;
  padding: 0;
  line-height: 2.5rem;
}
.ag-header-with-spinner .alert-banner__locked {
  font-size: 1.2rem;
  font-weight: 700;
  color: #c13939;
}
.ag-header-with-spinner .alert-banner__locked-msg {
  font-size: 1.2rem;
  color: #c13939;
}
.ag-header-with-spinner .alert-banner__lock-icon {
  font-size: 2.8rem;
}
.ag-header-with-spinner .alert-banner__lock-icon svg path,
.ag-header-with-spinner .alert-banner__lock-icon svg text {
  fill: #c13939;
}
.ag-header-with-spinner .alert-banner::v-deep svg {
  height: 2.6rem;
  width: 2.6rem;
}