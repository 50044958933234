/*Screen width*/
.nomination-templates {
  display: grid;
  grid-auto-flow: column;
  padding-bottom: 0.6rem;
}
.nomination-templates__column {
  display: flex;
  min-width: 16rem;
  margin-right: 4rem;
}
.nomination-templates__column--label {
  width: 10rem;
  line-height: 3rem;
  font-size: 1.1rem;
  font-weight: bold;
}
.nomination-templates__column--input {
  min-width: 16rem;
}
.nomination-templates__column--save {
  margin-top: 0.2rem;
}
.nomination-templates__column-name {
  min-width: 26rem;
  margin-right: 2rem;
}