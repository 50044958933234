.v-btn:not(.v-btn--outlined).primary, .v-btn:not(.v-btn--outlined).secondary, .v-btn:not(.v-btn--outlined).accent, .v-btn:not(.v-btn--outlined).success, .v-btn:not(.v-btn--outlined).error, .v-btn:not(.v-btn--outlined).warning, .v-btn:not(.v-btn--outlined).info {
  color: #FFFFFF;
}

.theme--light.v-btn {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-btn.v-btn--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(0, 0, 0, 0.26) !important;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #f5f5f5;
}
.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme--light.v-btn.v-btn--icon {
  color: rgba(0, 0, 0, 0.54);
}
.theme--light.v-btn:hover::before {
  opacity: 0.04;
}
.theme--light.v-btn:focus::before {
  opacity: 0.12;
}
.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  opacity: 0.12;
}
.theme--light.v-btn--active:focus::before {
  opacity: 0.16;
}

.theme--dark.v-btn {
  color: #FFFFFF;
}
.theme--dark.v-btn.v-btn--disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}
.theme--dark.v-btn.v-btn--disabled .v-icon,
.theme--dark.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(255, 255, 255, 0.3) !important;
}
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgba(255, 255, 255, 0.12) !important;
}
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #212121;
}
.theme--dark.v-btn.v-btn--outlined.v-btn--text {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme--dark.v-btn.v-btn--icon {
  color: #FFFFFF;
}
.theme--dark.v-btn:hover::before {
  opacity: 0.08;
}
.theme--dark.v-btn:focus::before {
  opacity: 0.24;
}
.theme--dark.v-btn--active:hover::before, .theme--dark.v-btn--active::before {
  opacity: 0.24;
}
.theme--dark.v-btn--active:focus::before {
  opacity: 0.32;
}

.v-btn {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.v-btn.v-size--x-small {
  font-size: 0.625rem;
}
.v-btn.v-size--small {
  font-size: 0.75rem;
}
.v-btn.v-size--default {
  font-size: 0.875rem;
}
.v-btn.v-size--large {
  font-size: 0.875rem;
}
.v-btn.v-size--x-large {
  font-size: 1rem;
}
.v-btn:before {
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.v-btn:before {
  background-color: currentColor;
}
.v-btn:not(.v-btn--disabled) {
  will-change: box-shadow;
}
.v-btn:not(.v-btn--round).v-size--x-small {
  height: 20px;
  min-width: 36px;
  padding: 0 8.8888888889px;
}
.v-btn:not(.v-btn--round).v-size--small {
  height: 28px;
  min-width: 50px;
  padding: 0 12.4444444444px;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
}
.v-btn:not(.v-btn--round).v-size--large {
  height: 44px;
  min-width: 78px;
  padding: 0 19.5555555556px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
  height: 52px;
  min-width: 92px;
  padding: 0 23.1111111111px;
}
.v-btn > .v-btn__content .v-icon {
  color: inherit;
}

.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
}
.v-btn__content .v-icon--left,
.v-btn__content .v-icon--right {
  font-size: 18px;
  height: 18px;
  width: 18px;
}
.v-application--is-ltr .v-btn__content .v-icon--left {
  margin-left: -4px;
  margin-right: 8px;
}
.v-application--is-rtl .v-btn__content .v-icon--left {
  margin-left: 8px;
  margin-right: -4px;
}
.v-application--is-ltr .v-btn__content .v-icon--right {
  margin-left: 8px;
  margin-right: -4px;
}
.v-application--is-rtl .v-btn__content .v-icon--right {
  margin-left: -4px;
  margin-right: 8px;
}

.v-btn__loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
  opacity: 0.18;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0.08;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
  opacity: 0.24;
}

.v-btn--absolute,
.v-btn--fixed {
  position: absolute;
}
.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: 16px;
}
.v-btn--absolute.v-btn--left,
.v-btn--fixed.v-btn--left {
  left: 16px;
}
.v-btn--absolute.v-btn--top,
.v-btn--fixed.v-btn--top {
  top: 16px;
}
.v-btn--absolute.v-btn--bottom,
.v-btn--fixed.v-btn--bottom {
  bottom: 16px;
}

.v-btn--block {
  display: flex;
  flex: 1 0 auto;
  min-width: 100% !important;
  max-width: auto;
}

.v-btn--contained {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-btn--contained:after {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.v-btn--contained:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.v-btn--depressed {
  box-shadow: none !important;
}

.v-btn--disabled {
  box-shadow: none;
  pointer-events: none;
}

.v-btn--icon,
.v-btn--fab {
  min-height: 0;
  min-width: 0;
  padding: 0;
}
.v-btn--icon.v-size--x-small .v-icon,
.v-btn--fab.v-size--x-small .v-icon {
  height: 18px;
  font-size: 18px;
  width: 18px;
}
.v-btn--icon.v-size--small .v-icon,
.v-btn--fab.v-size--small .v-icon {
  height: 24px;
  font-size: 24px;
  width: 24px;
}
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  height: 24px;
  font-size: 24px;
  width: 24px;
}
.v-btn--icon.v-size--large .v-icon,
.v-btn--fab.v-size--large .v-icon {
  height: 28px;
  font-size: 28px;
  width: 28px;
}
.v-btn--icon.v-size--x-large .v-icon,
.v-btn--fab.v-size--x-large .v-icon {
  height: 32px;
  font-size: 32px;
  width: 32px;
}

.v-btn--icon.v-size--x-small {
  height: 20px;
  width: 20px;
}
.v-btn--icon.v-size--small {
  height: 28px;
  width: 28px;
}
.v-btn--icon.v-size--default {
  height: 36px;
  width: 36px;
}
.v-btn--icon.v-size--large {
  height: 44px;
  width: 44px;
}
.v-btn--icon.v-size--x-large {
  height: 52px;
  width: 52px;
}

.v-btn--fab.v-btn--contained {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.v-btn--fab.v-btn--contained:after {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.v-btn--fab.v-btn--contained:active {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.v-btn--fab.v-btn--fixed, .v-btn--fab.v-btn--absolute {
  z-index: 4;
}
.v-btn--fab.v-size--x-small {
  height: 32px;
  width: 32px;
}
.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--bottom {
  bottom: -16px;
}
.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--top {
  top: -16px;
}
.v-btn--fab.v-size--small {
  height: 40px;
  width: 40px;
}
.v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom {
  bottom: -20px;
}
.v-btn--fab.v-size--small.v-btn--absolute.v-btn--top {
  top: -20px;
}
.v-btn--fab.v-size--default {
  height: 56px;
  width: 56px;
}
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: -28px;
}
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: -28px;
}
.v-btn--fab.v-size--large {
  height: 64px;
  width: 64px;
}
.v-btn--fab.v-size--large.v-btn--absolute.v-btn--bottom {
  bottom: -32px;
}
.v-btn--fab.v-size--large.v-btn--absolute.v-btn--top {
  top: -32px;
}
.v-btn--fab.v-size--x-large {
  height: 72px;
  width: 72px;
}
.v-btn--fab.v-size--x-large.v-btn--absolute.v-btn--bottom {
  bottom: -36px;
}
.v-btn--fab.v-size--x-large.v-btn--absolute.v-btn--top {
  top: -36px;
}

.v-btn--fixed {
  position: fixed;
}

.v-btn--loading {
  pointer-events: none;
  transition: none;
}
.v-btn--loading .v-btn__content {
  opacity: 0;
}

.v-btn--outlined {
  border: thin solid currentColor;
}
.v-btn--outlined:before {
  border-radius: 0;
}

.v-btn--outlined .v-btn__content .v-icon,
.v-btn--round .v-btn__content .v-icon {
  color: currentColor;
}

.v-btn--outlined,
.v-btn--flat,
.v-btn--text {
  background-color: transparent;
}

.v-btn--round:before,
.v-btn--rounded:before {
  border-radius: inherit;
}

.v-btn--round {
  border-radius: 50%;
}

.v-btn--rounded {
  border-radius: 28px;
}

.v-btn--tile {
  border-radius: 0;
}