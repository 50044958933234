.product-group__move_icon {
  height: 1.8rem !important;
  width: 100%;
  margin-top: 1.1rem;
}
.product-group .v-text-field.rtls-text-field .v-input__slot {
  height: 20px;
  padding-bottom: 5px;
}
.product-group .v-input.v-text-field.rtls-text-field .v-text-field__slot input {
  padding-top: 0px;
  padding-bottom: 0px;
}
.product-group .v-text-field.rtls-text-field .v-text-field__slot {
  height: 20px;
}
.product-group .v-chip.v-size--small {
  height: 20px;
}