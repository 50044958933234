/*Screen width*/
.promotion-categories {
  display: flex;
  column-gap: 1rem;
}
.promotion-categories__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.promotion-categories__list {
  margin-bottom: 1rem;
}
.promotion-categories__list .category {
  font-weight: bold;
  padding: 0.5rem 0;
  border-bottom: 0.1rem solid #e7e7e7;
}
.promotion-categories__divider {
  border-color: #8ba4b7;
}
.promotion-categories ::v-deep .v-chip {
  height: 2rem;
}

.labels-container {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}
.labels-container .label {
  width: 50%;
  font-size: 1.2rem;
}