/*Screen width*/
.rtls-select-container .rtls-select::v-deep .v-input__control .v-input__slot .v-select__slot input {
  padding-left: 0.5rem;
}
.rtls-select-container .rtls-select::v-deep .v-input__control .v-input__slot .v-select__slot input::placeholder {
  color: #9b9b9b;
}
.rtls-select-container .rtls-select::v-deep .v-input__control .v-input__slot .v-select__slot .v-select__selections span.truncate-text {
  padding-left: 0.5rem;
}
.rtls-select-container .v-input--is-disabled .theme--light.v-icon {
  opacity: 0.3;
}

.v-menu__content {
  border-radius: 0 !important;
}

.mdi-close-circle {
  margin-left: 1rem;
}
.mdi-close-circle:hover {
  color: #000000 !important;
}

#mdi:hover {
  color: #000000 !important;
}

.v-list-item--disabled > .v-list-item__content {
  color: #c7cdda !important;
}

.v-list-item__title {
  font-size: 1.2rem;
}