/*Screen width*/
.wrapper-scrollable {
  display: grid;
  grid-template-columns: 2.7rem auto 2.7rem;
  align-items: center;
}

.wrapper-pagination {
  width: 18.3rem;
  overflow: hidden;
  position: relative;
  margin-left: 1rem;
}

.v-pagination {
  width: auto;
}
.v-pagination li.no-border {
  border: none;
  padding: 0;
}
.v-pagination li.no-border + li {
  padding: 0;
}
.v-pagination .v-pagination__item {
  min-width: 2rem;
  width: 2rem;
  height: 2rem !important;
  padding: 0;
  border-radius: 0.2rem;
  border: 1px solid #2f477c;
  color: #2f477c;
}
.v-pagination .v-pagination__item.v-pagination__item--active {
  color: #ffffff;
}
.v-pagination .v-pagination__item--colored {
  color: #3d8113;
  border-color: #3d8113;
}
.v-pagination .v-pagination__item--colored.v-pagination__item--active {
  background-color: #3d8113 !important;
  color: #ffffff;
}
.v-pagination .v-pagination__item--highlighted {
  background-color: #e3edf5 !important;
}
.v-pagination .v-pagination__item:focus {
  outline: none;
}
.v-pagination .v-pagination__ellipsis--start, .v-pagination .v-pagination__ellipsis--end {
  height: 2.6rem;
  letter-spacing: 0.2rem;
  padding: 0 0.4rem !important;
  font-weight: bold;
}
.v-pagination .v-pagination__ellipsis--start {
  border-left: 1px solid #e7e7e7;
}
.v-pagination .v-pagination__ellipsis--end {
  border-right: 1px solid #e7e7e7;
}
.v-pagination .v-pagination__first-page {
  padding-right: 0.4rem;
}
.v-pagination .v-pagination__last-page {
  padding-left: 0.4rem;
}
.v-pagination.v-pagination--scrollable {
  margin-left: 0.5rem;
  padding-left: 0.4rem;
}