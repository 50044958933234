/*Screen width*/
.supplier-commitment-form-wrapper {
  display: contents;
}
.supplier-commitment-form-wrapper__view-promotions {
  grid-column: 1/last-column;
  padding: 0 1rem;
  background: #ffffff;
}
.supplier-commitment-form-wrapper__buttons {
  background: #ffffff;
  display: grid;
  grid-template-columns: 25% auto;
  grid-column: 1/last-column;
  border-top: #c2c2c2 0.1rem solid;
  padding: 1.5rem 0 1.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.supplier-commitment-form-wrapper__buttons--save {
  text-align: right;
}
.supplier-commitment-form-wrapper__buttons--save .save-btn {
  padding: 0 1.2rem;
  height: 2rem;
  margin-left: 1rem;
}