/*Screen width*/
.flex-row {
  display: flex;
  flex-direction: row !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
  margin-right: 0.5rem;
}

.v-input--indeterminate ::v-deep .mdi.mdi-minus-box {
  transition: none !important;
}
.v-input--indeterminate ::v-deep .mdi.mdi-minus-box::before {
  content: " ";
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff;
  border-radius: 0.2rem;
  border: 0.4rem solid #2f477c;
  transition: none;
}

::v-deep .v-input--checkbox.rtls-checkbox .v-input--selection-controls__input .v-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.promotion-name {
  margin-left: 1rem;
}