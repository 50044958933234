/*Screen width*/
.notifications-list {
  padding-top: 0;
}
.notifications-list .notification {
  border-bottom: 0.1rem solid #e7e7e7;
}
.notifications-list .notification__title {
  display: flex;
  font-size: 1.3rem;
}
.notifications-list .notification__content {
  white-space: initial;
  font-size: 1.2rem;
}
.notifications-list .notification__icon {
  align-self: auto;
}
.notifications-list__no-notifications-label {
  color: #37424a;
  font-weight: normal;
  padding: 2rem;
}