/*Screen width*/
.workflow-task-alert-list {
  width: 90%;
}
.workflow-task-alert-list__header {
  color: #37424a;
  font-size: 1.2rem;
  font-weight: 600;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: inline-block;
  width: 100%;
}
.workflow-task-alert-list__header .alert-count-badges {
  float: right;
  display: flex;
  flex-direction: row;
}
.workflow-task-alert-list__header .alert-count-badges > * {
  margin-right: 0.2rem;
}