/*Screen width*/
.alternative-mechanics {
  padding-top: 2.6rem;
  padding-left: 0.5rem;
}
.alternative-mechanics ::v-deep .expand-btn__icon {
  font-size: 3.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.alternative-mechanics ::v-deep .ag-body-viewport {
  padding-bottom: 15px;
}
.alternative-mechanics .v-divider {
  margin-top: 1rem;
}
.alternative-mechanics__header {
  display: flex;
}
.alternative-mechanics__header .expand-btn {
  display: flex;
}
.alternative-mechanics__overrides-warning {
  margin-left: 2rem;
}
.alternative-mechanics__collapse {
  font-size: 1.4rem;
  font-weight: 700;
}
.alternative-mechanics__generated-last {
  font-size: 1.2rem;
  padding-left: 1.5rem;
}
.alternative-mechanics__generated-last-warning {
  padding-left: 1rem;
}
.alternative-mechanics-viewer__grid ::v-deep .ag-row-focus {
  background-color: #eff5f6;
}
.alternative-mechanics-viewer__grid ::v-deep .promotion-rag-colour {
  margin-top: 1rem !important;
}