/*Screen width*/
.file-upload__input {
  width: 0.01rem;
  height: 0.01rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-upload__label {
  display: inline-block;
  padding: 0 1rem;
  background-color: transparent;
  border: thin solid #2f477c;
  border-radius: 0.4rem;
  color: #2f477c;
  height: 2.4rem;
}
.file-upload__label:hover {
  cursor: pointer;
  color: #000000;
  border-color: #000000;
}
.file-upload__label:hover span > svg path {
  fill: #000000;
}
.file-upload__label.disabled {
  opacity: 0.2;
}
.file-upload__label-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.2rem;
}