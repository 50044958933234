/*Screen width*/
.summary-header {
  flex-grow: 1;
  height: 100%;
  display: flex;
  margin: 0 0 0 0.6rem;
  align-items: center;
  justify-content: space-between;
}
.summary-header__actions {
  display: flex;
}
.summary-header__label {
  align-self: center;
  white-space: nowrap;
  padding-right: 1rem;
}
.summary-header .expand-btn {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.5rem;
}

.attribute-dialog {
  width: 40rem;
}
.attribute-dialog__select {
  width: 100%;
}