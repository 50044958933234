/*Screen width*/
.supplier-commitments {
  padding: 1rem;
}

.supplier-commitments-grid {
  display: grid;
  grid-template-columns: 20rem auto 30rem 15rem 15rem 30rem 5rem 5rem 6rem [last-column];
  color: #37424a;
}
.supplier-commitments-grid .supplier-commitments-grid__header {
  padding: 0.6rem 1rem;
}
.supplier-commitments-grid::v-deep .supplier-commitment-viewer > div:first-child .supplier-commitment-viewer__grid-cell {
  font-weight: 400;
}
.supplier-commitments-grid::v-deep .supplier-commitments-grid__header + .supplier-commitment-viewer-wrapper .supplier-commitment-viewer__grid-cell {
  border-top-color: #c2c2c2;
}