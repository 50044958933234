/*Screen width*/
.pagination-container {
  display: flex;
  flex-direction: row;
}
.pagination-container__select::v-deep .v-select__slot {
  background-color: #f2f3f4;
}
.pagination-container__select::v-deep .v-select__slot .v-label {
  padding-left: 1rem;
  padding-right: 0.6rem;
}
.pagination-container__select::v-deep .v-select__selections {
  line-height: 1.6rem;
}
.pagination-container__select::v-deep .v-select__selections input {
  width: 0;
}
.pagination-container__select::v-deep .v-select__selection.v-select__selection--comma {
  padding-left: 1rem !important;
}
.pagination-container__select::v-deep .v-select__slot,
.pagination-container__select::v-deep .v-input__slot {
  height: 2.2rem !important;
}
.pagination-container__no-of-pages {
  font-size: 1.2rem;
  line-height: 2.4rem;
  padding: 0 1rem 0 1rem;
}
.pagination-container__label {
  font-size: 1.2rem;
  line-height: 2.4rem;
  padding: 0 1rem 0 3rem;
}
.pagination-container__disabled .pagination-container__label {
  color: #c2c2c2;
}