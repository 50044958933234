/*Screen width*/
::v-deep .rtls-expansion-panel .rtls-expansion-panel-header .rtls-expansion-panel-header__label {
  font-size: 1.3rem;
  color: #2f477c;
}

.sp-promotions {
  display: flex;
  padding-bottom: 2rem;
}
.sp-promotions__title {
  width: 17rem;
  text-align: right;
  font-size: 1.3rem;
  padding-right: 1rem;
}