.rtls-expansion-panel .rtls-expansion-panel-header {
  padding: 0;
}
.rtls-expansion-panel .rtls-expansion-panel-header > * {
  flex-grow: 0;
}
.rtls-expansion-panel .rtls-expansion-panel-header .asterisk {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2rem;
  padding-left: 0.3rem;
}
.rtls-expansion-panel .rtls-expansion-panel-header__label {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
}