/*Screen width*/
.parent-permalink {
  float: left;
  text-transform: uppercase;
}
.parent-permalink .parent-badge {
  background: #3b80b1 !important;
  color: #ffffff;
  transform: scale(0.8);
  float: left;
  margin-left: -0.5rem;
}
.parent-permalink .link {
  float: left;
  font-weight: bold;
  text-transform: none;
  cursor: pointer;
  color: #2f477c;
  font-size: 1.1rem;
  padding-top: 0.4rem;
  width: 19rem;
}
.parent-permalink .link p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 17rem;
  float: left;
}
.parent-permalink .link i {
  font-size: 1.5rem;
}
.parent-permalink .link:hover {
  text-decoration: underline;
}