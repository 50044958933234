/*Screen width*/
.main__input {
  padding-bottom: 0.7rem;
}

.add-additional-reward-btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.horizontal-divider {
  border-color: #aecadb;
}