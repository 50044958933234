/*Screen width*/
.area {
  color: #ffffff;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.area__inner--category {
  width: 100%;
  height: 100%;
}
.area__inner--category-active {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 0.4rem solid #aecadb;
  position: relative;
  overflow: hidden;
}
.area__inner--category-active .assignments-container {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 90%;
  height: 80%;
  color: #37424a;
  font-size: 1.2rem;
}
.area__inner--category-active .assignments-container .category-label {
  font-weight: 600;
}
.area__inner--category-active .assignments-container .category-warning {
  color: #c13939;
  padding-left: 1rem;
}
.area__inner--category-active .assignments-container .category-warning ::v-deep .v-icon {
  padding-bottom: 0.3rem;
}
.area__inner--category-active .assignments-container .category-warning--text {
  font-weight: 600;
  padding-left: 0.2rem;
}
.area__inner--category-active .assignments-container .promotion {
  display: flex;
  flex-wrap: wrap;
}
.area__inner--category-active .assignments-container .promotion__products {
  padding: 0 1rem;
}
.area__inner--category-active .assignments-container .promotion__name {
  font-weight: 600;
}
.area__inner--category-active .assignments-container .promotion__offer-mechanic {
  font-weight: 600;
  width: 100%;
}
.area__inner--category-active .assignments-container .promotion__assigned-products {
  font-weight: 600;
  text-align: center;
  margin-top: 1.5rem;
}
.area__inner--category-active .category-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.area__inner--category-active-in-promotions-tab {
  border-color: #00a0e2;
}

.non-allocated {
  background: #ded4ed;
  border: none;
}

.area-simple {
  background-color: #e7e7e7;
}

.area-saved {
  background-color: #d3dee7;
}

.area-selected {
  background-color: #aecadb;
}

.area-highlighted {
  background-color: #e3edf5;
}