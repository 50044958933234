/*Screen width*/
.bulk-upload {
  font-size: 1.3rem;
}

::v-deep .v-text-area__slot label {
  padding-left: 1rem;
}
::v-deep .spinner {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.product-key-area--input {
  height: 15rem;
}
.product-key-area--input ::v-deep .v-textarea {
  border-radius: 0;
  box-shadow: 0;
}
.product-key-area--input ::v-deep .v-label {
  top: 0.5rem !important;
}
.product-key-area--input ::v-deep .v-input__slot .v-text-field__slot textarea {
  margin: 0;
  margin-top: 0.5rem;
  height: 12rem;
  overflow-y: auto;
  padding-left: 1rem;
}
.product-key-area--report {
  display: flex;
  justify-content: space-between;
  height: 6rem;
}
.product-key-area--report--warning {
  color: red;
}
.product-key-area--report .in-progress__message {
  font-size: 1.3rem;
  color: #37424a;
}