/*Screen width*/
.select-all-btn.v-btn:not(.v-btn--round).v-size--x-small {
  height: 1.5rem;
  min-width: 3.3rem;
}

.select-all-btn.v-btn:not(.v-btn--round).v-size--x-small:hover {
  background-color: #000000 !important;
}

.select-all-btn.v-btn:not(.v-btn--round).v-size--x-small:disabled {
  background-color: #c7cdda !important;
}

.v-list-item {
  font-size: 1.2rem;
  min-height: 3.5rem;
}

.sort-icon {
  padding-left: 0.5rem;
  font-family: "agGridAlpine" !important;
}

.label {
  font-weight: normal;
  font-size: 1.2rem;
  white-space: normal;
  margin-bottom: 0.25rem;
}