/*Screen width*/
.notes-wrapper {
  z-index: 205 !important;
  border-radius: 0;
}

.notes {
  width: 32rem;
  max-height: 85vh;
  overflow-y: auto;
  background: #ffffff;
  padding: 0.7rem;
  border-left: 0.5rem solid #2f477c;
}
.notes__header {
  font-size: 1.4rem;
  padding: 0.3rem 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
}
.notes__container {
  display: flex;
  background: #f2f3f4;
  padding: 1rem 0.7rem;
  margin-bottom: 0.5rem;
}
.notes__author {
  height: 2.6rem;
  width: 2.6rem;
}
.notes__content {
  width: calc(100% - 3.6rem);
  padding-left: 1rem;
}
.notes__date {
  font-weight: bold;
  font-size: 1.2rem;
}
.notes__date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notes__input-container {
  padding: 1rem 1rem 0.5rem;
  background: #dfebee;
}
.notes__button {
  position: relative;
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
}
.notes__text {
  padding-right: 0.5rem;
  font-size: 1.2rem;
  word-break: break-word;
}
.notes__input ::v-deep .v-textarea {
  border-radius: 0;
  box-shadow: 0;
}
.notes__input ::v-deep .v-label {
  top: 0.5rem !important;
}
.notes__input ::v-deep .v-input__slot .v-text-field__slot textarea {
  margin: 0;
}