/*Screen width*/
.confirm-dialog__header {
  text-align: right;
}
.confirm-dialog__container {
  width: 45rem;
  border-left: 0.4rem solid #2f477c;
}
.confirm-dialog__action {
  padding: 0 3.5rem;
}