/*Screen width*/
.promotion-viewer {
  font-size: 1.2rem;
  display: contents;
  position: relative;
}
.promotion-viewer__data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.3rem 1rem;
  min-height: 5rem;
  height: 100%;
  background-color: #ffffff;
  border-top: #aecadb 0.2rem solid;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #4a4a4a;
  letter-spacing: 0;
}
.promotion-viewer__data--expanded {
  border-bottom: none;
}
.promotion-viewer__data:hover {
  cursor: pointer;
}
.promotion-viewer__border-colour--1 {
  border-top-color: #d7534e;
}
.promotion-viewer__border-colour--2 {
  border-top-color: #ea9b66;
}
.promotion-viewer__border-colour--3 {
  border-top-color: #93ab26;
}
.promotion-viewer__border-colour--4 {
  border-top-color: #3d8113;
}
.promotion-viewer__border-colour--5 {
  border-top-color: #c738b4;
}
.promotion-viewer__grid-cell {
  grid-column: span 1;
}
.promotion-viewer__grid-cell--main-content {
  padding-top: 0.5rem;
  background-color: #f5f5f5;
}
.promotion-viewer__grid-cell--sidebar {
  z-index: 93;
  background-color: #ffffff;
}
.promotion-viewer__grid-cell--sidebar .promotion-viewer__data {
  border-bottom: none;
}
.promotion-viewer--first-item {
  margin-left: 1.5rem;
  grid-column-start: 1;
}
.promotion-viewer__maintenance-pane {
  grid-column-start: 1;
  grid-column-end: -1;
}
.promotion-viewer__extra-options {
  background-color: #ffffff;
  border-bottom: #d3d3d3 0.1rem solid;
}
.promotion-viewer__extra-options.expanded-promotion {
  border-bottom: unset;
}
.promotion-viewer__extra-options:hover {
  cursor: pointer;
}
.promotion-viewer__extra-options.extra-options__tag-selector {
  grid-column-start: 1;
  grid-column-end: -4;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.audit-user-details {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.promotion--faded .promotion-viewer__data,
.promotion--faded .promotion-viewer__extra-options {
  opacity: 0.65;
}

.promotion-viewer__overlay {
  z-index: 99 !important;
  grid-column-start: 1;
  grid-column-end: -1;
}