/*Screen width*/
.confirm {
  box-shadow: none;
}
.confirm__container {
  line-height: 1.5rem;
}
.confirm__container p {
  color: #37424a;
}
.confirm__question {
  font-size: 1.4rem;
}
.confirm__warning {
  font-weight: 600;
  font-size: 1.2rem;
}
.confirm__cancel {
  color: #2f477c;
}