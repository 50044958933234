/*Screen width*/
.chart-container {
  height: 100%;
  width: 50%;
  margin: 1rem;
}

.details-btn {
  align-self: flex-end;
  margin-right: 1.5rem;
}

.forecast-details-container {
  display: flex;
  flex-direction: column;
}
.forecast-details-container .waterfall-charts {
  display: flex;
  height: 32rem;
}
.forecast-details-container .filter {
  height: 3rem;
}
.forecast-details-container__filters {
  display: flex;
  align-items: baseline;
  width: 70%;
  height: 4rem;
  margin-top: 1rem;
}
.forecast-details-container__filters--label {
  font-size: 1.2rem;
  padding-right: 1rem;
}
.forecast-details-container__filters--select {
  width: 80%;
}

::v-deep .product-information__metrics {
  justify-content: center;
  text-align: right;
}