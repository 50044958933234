/*Screen width*/
.variable-funding-agreements-viewer {
  padding-bottom: 2rem;
}
.variable-funding-agreements-viewer__grid {
  display: grid;
  grid-template-columns: 20rem 20rem 15rem 20rem 20rem 20rem auto 5rem 20rem 4rem 4rem 4rem 4rem [last-column];
  background-color: #ffffff;
}
.variable-funding-agreements-viewer__header {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #37424a;
  background-color: #ffffff;
  padding-bottom: 0.3rem;
  border-bottom: 0.1rem solid #c2c2c2;
  padding-left: 1rem;
}
.variable-funding-agreements-viewer__header:first-child {
  padding-left: 1.5rem;
}
.variable-funding-agreements-viewer__header.last {
  grid-column-end: span 4;
  padding-right: 1.5rem;
}
.variable-funding-agreements-viewer__header.bold {
  font-weight: 600;
}
.variable-funding-agreements-viewer__new-funding {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  border-top: #dddddd 0.1rem solid;
  padding: 1rem 0;
  margin-top: 1.5rem;
}
.variable-funding-agreements-viewer__new-single-funding {
  margin-right: 1.5rem;
}
.variable-funding-agreements-viewer__new-funding-bulk-upload {
  margin-left: 1.5rem;
}
.variable-funding-agreements-viewer__choose-file {
  width: 0.01rem;
  height: 0.01rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.variable-funding-agreements-viewer__choose-file-label {
  display: inline-block;
  padding: 0 1rem;
  margin-right: 1.5rem;
  background-color: transparent;
  border: thin solid #2f477c;
  border-radius: 0.4rem;
  color: #2f477c;
}
.variable-funding-agreements-viewer__choose-file-label:hover {
  cursor: pointer;
  color: #000000;
  border-color: #000000;
}
.variable-funding-agreements-viewer__choose-file-label-contents {
  display: flex;
  align-items: center;
  justify-content: center;
}
.variable-funding-agreements-viewer .filler {
  grid-column-start: 1;
  grid-column-end: -1;
  height: 1rem;
  background-color: #f2f3f4;
}