/*Screen width*/
.selectors {
  display: flex;
  flex: 1;
  align-items: baseline;
}
.selectors > * {
  margin-right: 1rem;
}
.selectors__select {
  width: 20rem;
  max-width: 20rem;
  align-self: center;
}
.selectors__select::v-deep .v-select__slot,
.selectors__select::v-deep .v-input__slot {
  padding-left: 0.5rem;
}

.details {
  width: 75%;
  display: flex;
  align-items: baseline;
  margin-left: 2rem;
  margin-top: 1rem;
}
.details__label {
  font-size: 1.2rem;
  text-align: right;
  padding: 0;
}
.details__content {
  margin-left: 1rem;
  font-size: 1.2rem;
  padding: 0;
}