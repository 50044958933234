.candidates-grid-container {
  border-bottom: solid;
  border-bottom-width: 0.75rem !important;
  border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;
}
.candidates-grid-container .ag-theme-custom__candidates-grid::v-deep .ag-row {
  height: 3.6rem !important;
}
.candidates-grid-container .ag-theme-custom__candidates-grid::v-deep .ag-header {
  border-left: none;
  border-right: none;
}
.candidates-grid-container .ag-theme-custom__candidates-grid::v-deep .ag-center-cols-viewport {
  border-left: none;
  border-right: none;
}