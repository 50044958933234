/*Screen width*/
.select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2.5rem;
}
.select-container .ag-select-input,
.select-container .ag-label {
  display: flex;
  width: 100%;
  height: 2.5rem;
  padding-left: 0.5rem;
}
.select-container .ag-select-input.disabled,
.select-container .ag-label.disabled {
  background-color: transparent !important;
}
.select-container ::v-deep.select-arrow {
  position: absolute;
  right: 1.5rem;
  padding-top: 0.25rem;
  font-size: 1.8rem;
  color: #2f477c;
  pointer-events: none;
}