/*Screen width*/
.sell-in-container {
  display: flex;
  flex-direction: row;
  margin: 0 1rem;
  align-items: center;
  background-color: #ffffff;
}
.sell-in-container.disabled {
  background-color: transparent !important;
}
.sell-in-container .spacer {
  margin-right: 0.7rem;
}
.sell-in-container .inputs__sell-in-period-start, .sell-in-container .inputs__sell-in-period-end {
  margin-right: 1rem;
}
.sell-in-container ::v-deep .inputs__sell-in-period-start .v-messages__message {
  margin-left: -1.6rem;
}
.sell-in-container ::v-deep .inputs__sell-in-period-end .v-messages__message {
  margin-left: 0;
}
.sell-in-container ::v-deep .v-messages__message {
  position: absolute;
  width: 7rem;
  margin-top: 0.2rem;
  line-height: 1.3rem;
  font-size: 1rem;
  background-color: #d3dee7;
}