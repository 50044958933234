/*Screen width*/
.campaign-container {
  color: #37424a;
  max-width: 83rem;
}
.campaign-container::v-deep .vuex-form {
  display: grid;
  grid-template: "title description dates" "link description workflow" "details details details" "store-groups store-groups store-groups" "nominations nominations nominations " "tags tags tags" "children children children" "rate-cards rate-cards rate-cards"/1fr 1fr 1fr;
}
.campaign-container::v-deep .vuex-form__dates {
  grid-area: dates;
}
.campaign-container::v-deep .vuex-form__link {
  grid-area: link;
}
.campaign-container::v-deep .vuex-form__workflow {
  grid-area: workflow;
}
.campaign-container::v-deep .vuex-form__details {
  grid-area: details;
  border-top: 1px solid #e7e7e7;
  margin: 0 !important;
}
.campaign-container::v-deep .vuex-form__details .details__wrapper {
  display: grid;
  grid-template: "categories . . regions" "promo-resources custom-restrictions . .";
}
.campaign-container::v-deep .vuex-form__nominations {
  grid-area: nominations;
  border-top: 1px solid #e7e7e7;
  margin: 0 !important;
  padding-bottom: 1rem;
}
.campaign-container::v-deep .vuex-form__tags {
  grid-area: tags;
  border-top: 1px solid #e7e7e7;
  margin: 0 !important;
  padding-bottom: 1rem;
}
.campaign-container::v-deep .vuex-form__children {
  grid-area: children;
  border-top: 1px solid #e7e7e7;
  margin: 0 !important;
  padding-bottom: 1rem;
}
.campaign-container::v-deep .vuex-form__rate-cards {
  grid-area: rate-cards;
  border-top: 1px solid #e7e7e7;
  margin: 0 !important;
  padding-bottom: 1rem;
}
.campaign-container::v-deep .vuex-form__name {
  grid-area: title;
  padding-right: 2rem;
  width: 26.5rem;
}
.campaign-container::v-deep .vuex-form__briefing {
  grid-area: description;
  padding-right: 2rem;
  width: 38.5rem;
}
.campaign-container::v-deep .vuex-form .rtls-expansion-panel-content .v-expansion-panel-content__wrap {
  display: contents;
}
.campaign-container::v-deep .vuex-form__store-groups {
  grid-area: store-groups;
  border-top: 1px solid #e7e7e7;
  margin: 0 !important;
  padding-bottom: 1rem;
}
.campaign-container::v-deep .vuex-form__store-groups-selector {
  display: flex;
  min-width: 50%;
  max-width: 100%;
}
.campaign-container::v-deep .vuex-form__promo-resources {
  grid-area: promo-resources;
  padding-bottom: 1.4rem;
}
.campaign-container::v-deep .vuex-form__promo-resources .icon-checkboxes-list-container__control {
  padding: 0;
  display: flex;
  position: relative;
  border-right: 1px solid #e7e7e7;
}
.campaign-container::v-deep .vuex-form__promo-resources .icon-checkboxes-list-container__control .v-input--checkbox.error--text {
  padding-bottom: 1rem;
}
.campaign-container::v-deep .vuex-form__promo-resources .icon-checkboxes-list-container__control .v-messages.error--text {
  position: absolute;
  top: 2.5rem;
  left: 0;
}
.campaign-container::v-deep .vuex-form__categories {
  grid-area: categories;
  grid-column: 1/span 2;
}
.campaign-container::v-deep .vuex-form__customer-restrictions {
  grid-area: custom-restrictions;
  padding: 0;
}
.campaign-container::v-deep .vuex-form__customer-restrictions .checkboxes-list-container__control {
  display: flex;
  position: relative;
}
.campaign-container::v-deep .vuex-form__customer-restrictions .checkboxes-list-container__control .v-messages.error--text {
  position: absolute;
  top: 2rem;
  left: 0;
}
.campaign-container::v-deep .vuex-form__customer-restrictions .checkboxes-list-container__control .rtls-checkbox {
  margin-right: 1.5rem;
}
.campaign-container__bottom-container {
  display: flex;
  align-items: center;
}
.campaign-container__bottom-container .author-container {
  display: flex;
  justify-content: space-between;
  line-height: 2.1rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.campaign-container__bottom-container .author-container > p {
  margin-right: 1.3rem;
}
.campaign-container__bottom-container .v-divider {
  margin-right: 1.3rem;
  margin-left: 1.3rem;
}
.campaign-container__bottom-container-right {
  display: flex;
  margin-left: auto;
}
.campaign-container__author-avatar {
  background-color: #37424a;
  font-size: 1rem;
  font-weight: 400;
}
.campaign-container__last-updated {
  font-size: 1.2rem;
  font-weight: 600;
}
.campaign-container__last-updated .date {
  font-weight: 400;
}

.subcampaign-container {
  max-width: 90rem;
}
.subcampaign-container::v-deep .vuex-form .rtls-expansion-panel-content {
  padding-top: 1.5rem;
}