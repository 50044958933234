.revert-container {
  display: flex;
  flex-direction: column;
}
.revert-container .ag-revert-input {
  display: flex;
  width: 8rem;
}
.revert-container .ag-revert-input .button-container {
  width: 2rem;
}
.revert-container .ag-revert-input .input-container {
  width: 6.5rem;
}
.revert-container .ag-revert-input .input-container::v-deep .v-input__slot {
  height: 1.8rem;
}
.revert-container .initial-value {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.45rem;
  font-size: 1.1rem;
}