.theme--light.v-card {
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.theme--light.v-card.v-card--outlined {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-card {
  background-color: #1F1F1F;
  color: #FFFFFF;
}
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card .v-card__subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme--dark.v-card.v-card--outlined {
  border: thin solid rgba(255, 255, 255, 0.12);
}

.v-card {
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 4px;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-card__progress {
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.v-card__subtitle + .v-card__text {
  padding-top: 0;
}

.v-card__subtitle,
.v-card__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 16px;
}

.v-card__title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.v-card__title + .v-card__subtitle,
.v-card__title + .v-card__text {
  padding-top: 0;
}
.v-card__title + .v-card__subtitle {
  margin-top: -16px;
}

.v-card__text {
  width: 100%;
}

.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
}
.v-card__actions > .v-btn.v-btn {
  padding: 0 8px;
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 8px;
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn .v-icon--left {
  margin-left: 4px;
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn .v-icon--right {
  margin-right: 4px;
}
.v-application--is-rtl .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-right: 8px;
}
.v-application--is-rtl .v-card__actions > .v-btn.v-btn .v-icon--left {
  margin-right: 4px;
}
.v-application--is-rtl .v-card__actions > .v-btn.v-btn .v-icon--right {
  margin-left: 4px;
}

.v-card--flat {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.v-card--hover {
  cursor: pointer;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-card--hover:hover, .v-card--hover:focus {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.v-card--link {
  cursor: pointer;
}
.v-card--link .v-chip {
  cursor: pointer;
}
.v-card--link:focus:before {
  opacity: 0.08;
}
.v-card--link:before {
  background: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s opacity;
}

.v-card--disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-card--disabled > *:not(.v-card__progress) {
  opacity: 0.6;
  transition: inherit;
}

.v-card--loading {
  overflow: hidden;
}

.v-card--outlined {
  box-shadow: none;
}

.v-card--raised {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.v-card--shaped {
  border-radius: 24px 4px;
}