/*Screen width*/
.promotions__grid {
  display: grid;
  grid-template-columns: 3.4rem 22rem 10rem 42rem;
  position: relative;
}
.promotions__data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.75rem;
  padding-left: 1rem;
  min-height: 3.5rem;
  background-color: #ffffff;
  border-top: #aecadb 0.2rem solid;
  border-bottom: #d3d3d3 0.1rem solid;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #4a4a4a;
  letter-spacing: 0;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.05), 0 0.2rem 0 0 rgba(0, 0, 0, 0.05);
}
.promotions__data:hover {
  cursor: pointer;
}
.promotions__border-colour--1 {
  border-top-color: #d7534e;
}
.promotions__border-colour--2 {
  border-top-color: #ea9b66;
}
.promotions__border-colour--3 {
  border-top-color: #93ab26;
}
.promotions__border-colour--4 {
  border-top-color: #3d8113;
}
.promotions__border-colour--5 {
  border-top-color: #c738b4;
}
.promotions__grid-cell {
  padding-top: 0.5rem;
  display: contents;
}

.last-column {
  padding-right: 0.75rem;
}
.last-column .promotions__data {
  padding-top: 0;
  justify-content: center;
}