/*Screen width*/
.main-pagination.v-pagination {
  justify-content: flex-end;
}
.main-pagination.v-pagination::v-deep .v-pagination__navigation,
.main-pagination.v-pagination::v-deep .v-pagination__item {
  height: 2rem;
  width: 2rem;
  box-shadow: none;
}
.main-pagination.v-pagination::v-deep .v-pagination__item {
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  min-width: 2rem;
  color: #2f477c;
  border: 0.1rem solid #2f477c;
  line-height: 1rem;
}
.main-pagination.v-pagination::v-deep .v-pagination__item--active {
  color: #ffffff;
}
.main-pagination.v-pagination::v-deep .v-pagination__navigation .v-icon {
  color: #2f477c;
}