/*Screen width*/
.free-gifts-viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-bottom: 3rem;
  background-color: #ffffff;
}
.free-gifts-viewer .grid-controls {
  font-size: 1.4rem;
  padding-left: 3rem;
}

.free-gifts-viewer::v-deep .ag-theme-custom {
  width: 50% !important;
}
.free-gifts-viewer::v-deep .flex-cell-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.free-gifts-viewer::v-deep .flex-cell-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.free-gifts-viewer::v-deep .ag-center-cols-viewport {
  overflow-x: hidden;
}