.losenge-monetary-display {
  width: 100%;
}

.incremental-amount {
  font-size: 1.4rem;
}

.compact-incremental-amount {
  width: 50%;
  text-align: right;
}

.compact-efficiency {
  width: 50%;
  padding-left: 1rem;
  padding-right: 0.5rem;
  font-size: 1.2rem;
  text-align: right;
}