/*Screen width*/
.offer-mechanic-alternatives {
  margin: 2rem 0 1rem 1rem;
}

.offer-mechanic-preset {
  margin-left: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.offer-mechanic-preset__preset-selector {
  display: flex;
  column-gap: 2.1rem;
}
.offer-mechanic-preset__preset-selector--text {
  margin-top: 0.9rem;
}
.offer-mechanic-preset__preset-selector--select-preset {
  height: 2.1rem;
}
.offer-mechanic-preset__preset-selector--select-preset ::v-deep .v-input__slot,
.offer-mechanic-preset__preset-selector--select-preset ::v-deep .v-select__slot {
  height: 2.1rem !important;
  width: 30rem;
}

.offer-mechanic {
  display: grid;
  grid-template-columns: 5rem auto;
  max-width: calc(100vw - 29rem);
  overflow-x: auto;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.offer-mechanic__offer {
  color: #37424a;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-top: 0.8rem;
}
.offer-mechanic__tiers {
  border-top: 0.1rem solid #f5f5f5;
  margin-left: 0.6rem;
  display: flex;
}
.offer-mechanic__tier {
  display: flex;
}
.offer-mechanic__conditions--inner-container {
  padding: 0.5rem;
  background-color: #f5f5f5;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  padding-bottom: 0.1rem;
}
.offer-mechanic__reward-requirement {
  display: flex;
  flex-direction: row;
}
.offer-mechanic__reward-requirement .flex-row {
  align-items: center;
}
.offer-mechanic__expand {
  margin-right: 0.6rem;
  padding: 0.5rem 0.5rem 0.5rem 0.6rem;
  text-align: center;
  border-right: 0.1rem solid #dddddd;
}
.offer-mechanic__expand--collapsed {
  padding-left: 0;
}
.offer-mechanic__product-offer-group-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  margin-left: 0.6rem;
  color: #37424a;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.4rem;
}
.offer-mechanic__product-offer-group-drop-down {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.offer-mechanic__optional-check-box {
  margin-left: 0.9rem;
}
.offer-mechanic__drop-down {
  padding-right: 0.5rem !important;
  background-color: transparent !important;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #2f477c;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.4rem;
  text-align: right;
  margin-top: 0.8rem;
}
.offer-mechanic__drop-down:before {
  background-color: transparent !important;
}
.offer-mechanic__drop-down--add-condition {
  margin-bottom: 0.7rem;
  padding-left: 0 !important;
}
.offer-mechanic__drop-down .v-btn__content .offer-mechanic-icon-wrapper {
  border-radius: 0.3rem;
  border: 0.1rem solid #2f477c;
  width: 1.9rem;
  height: 1.9rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
}
.offer-mechanic__drop-down .v-btn__content .offer-mechanic-icon-wrapper.disabled {
  opacity: 0.5;
}
.offer-mechanic__drop-down .v-btn__content:hover .offer-mechanic-icon-wrapper {
  border-color: #000000;
}
.offer-mechanic__drop-down .v-btn__content:hover i {
  color: #000000 !important;
}
.offer-mechanic__drop-down :hover {
  background: transparent !important;
  color: #000000;
}
.offer-mechanic__drop-down--no-label {
  padding: 0 !important;
  min-width: 1.9rem !important;
  margin-top: 0;
}
.offer-mechanic__drop-down--no-label ::v-deep .v-btn__content {
  margin-left: 1rem;
}
.offer-mechanic__drop-down--with-conditions {
  min-width: 1rem !important;
  padding-left: 1rem !important;
}
.offer-mechanic__dropdown-icon-item {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.offer-mechanic__dropdown-icon-label {
  margin-left: 0.5rem;
}
.offer-mechanic__on-label {
  margin-right: 0.6rem;
}
.offer-mechanic__select {
  height: 2.1rem;
}
.offer-mechanic__select ::v-deep .v-input__slot,
.offer-mechanic__select ::v-deep .v-select__slot {
  height: 2.1rem !important;
}
.offer-mechanic__restrictions {
  max-width: 41rem;
}
.offer-mechanic__errors {
  margin: 0 1rem;
}
.offer-mechanic .expand-btn {
  width: 1.8rem;
  height: 1.8rem;
  margin-top: 0.8rem;
}

.v-btn :hover {
  background-color: #e6f5fc;
}

.v-list-item {
  font-size: 1.2rem;
  min-height: 2rem;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.4rem;
  padding-left: 0.8rem;
}