/*Screen width*/
.ranking-tab__products-count {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
}
.ranking-tab__products-count label {
  padding-right: 1rem;
}
.ranking-tab__products-count .rtls-select-container {
  width: 6rem;
}
.ranking-tab__products-count--warning {
  padding-left: 1rem;
  color: #ff5252;
}