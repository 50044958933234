/*Screen width*/
.text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;
  background-color: #ffffff;
}
.text-container.disabled {
  background-color: transparent !important;
}
.text-container .input--bold {
  font-weight: bold;
}
.text-container .ag-text-input {
  display: flex;
  width: 100%;
}
.text-container .ag-text-input .input-container {
  width: "fit-content";
}