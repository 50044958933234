/*Screen width*/
.promotion-tag-selector {
  display: flex;
}
.promotion-tag-selector__select {
  font-size: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  min-width: 25%;
  max-width: 100%;
}