/*Screen width*/
.loyalty-points-viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-bottom: 3rem;
  background-color: #ffffff;
}
.loyalty-points-viewer .grid-controls {
  font-size: 1.4rem;
  padding-left: 3rem;
}

.loyalty-points-viewer::v-deep .ag-theme-custom {
  max-width: 50% !important;
}
.loyalty-points-viewer::v-deep .flex-cell-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.loyalty-points-viewer::v-deep .flex-cell-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loyalty-points-viewer::v-deep .ag-center-cols-viewport {
  overflow-x: hidden;
}
.loyalty-points-viewer::v-deep .invalid-background {
  background: #fcedef;
}