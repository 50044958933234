/*Screen width*/
.promotions-orders__caret {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-left: 4px;
  cursor: pointer;
}
.promotions-orders__caret--up {
  border-bottom: 5px solid #2f477c;
  margin-bottom: 3px;
}
.promotions-orders__caret--down {
  border-top: 5px solid #2f477c;
}
.promotions-orders .active-down {
  border-top-color: #8ba4b7;
}
.promotions-orders .active-up {
  border-bottom-color: #8ba4b7;
}