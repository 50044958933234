/*Screen width*/
.subtype {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.subtype__select {
  padding-bottom: 1rem;
  max-width: 13rem;
}
.subtype__button {
  color: #2f477c;
  margin-left: 0.5rem;
  cursor: pointer;
}