.theme--light.v-bottom-navigation {
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: rgba(0, 0, 0, 0.6) !important;
}

.theme--dark.v-bottom-navigation {
  background-color: #1F1F1F;
  color: #FFFFFF;
}
.theme--dark.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: rgba(255, 255, 255, 0.7) !important;
}

.v-item-group.v-bottom-navigation {
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.v-item-group.v-bottom-navigation .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: transparent;
}
.v-item-group.v-bottom-navigation .v-btn {
  border-radius: 0;
  box-shadow: none;
  flex: 0 1 auto;
  font-size: 0.75rem;
  height: inherit;
  max-width: 168px;
  min-width: 80px;
  position: relative;
  text-transform: none;
}
.v-item-group.v-bottom-navigation .v-btn:after {
  content: none;
}
.v-item-group.v-bottom-navigation .v-btn .v-btn__content {
  flex-direction: column-reverse;
  height: inherit;
}
.v-item-group.v-bottom-navigation .v-btn .v-btn__content > *:not(.v-icon) {
  line-height: 1.2;
}
.v-item-group.v-bottom-navigation .v-btn.v-btn--active {
  color: inherit;
}
.v-item-group.v-bottom-navigation .v-btn.v-btn--active:not(:hover):before {
  opacity: 0;
}

.v-item-group.v-bottom-navigation--absolute,
.v-item-group.v-bottom-navigation--fixed {
  z-index: 4;
}

.v-item-group.v-bottom-navigation--absolute {
  position: absolute;
}

.v-item-group.v-bottom-navigation--active {
  transform: translate(0, 0);
}

.v-item-group.v-bottom-navigation--fixed {
  position: fixed;
}

.v-item-group.v-bottom-navigation--grow .v-btn {
  width: 100%;
}

.v-item-group.v-bottom-navigation--horizontal .v-btn > .v-btn__content {
  flex-direction: row-reverse;
}
.v-item-group.v-bottom-navigation--horizontal .v-btn > .v-btn__content > .v-icon {
  margin-bottom: 0;
  margin-right: 16px;
}

.v-item-group.v-bottom-navigation--shift .v-btn .v-btn__content > *:not(.v-icon) {
  opacity: 0;
  position: absolute;
  top: calc(100% - 12px);
  transform: scale(0.9);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-item-group.v-bottom-navigation--shift .v-btn--active .v-btn__content > .v-icon {
  transform: translateY(-8px);
}
.v-item-group.v-bottom-navigation--shift .v-btn--active .v-btn__content > *:not(.v-icon) {
  opacity: 1;
  top: calc(100% - 22px);
  transform: scale(1);
}