/*Screen width*/
.copy-resource__text {
  font-size: 1.2rem;
  font-weight: 1.2rem;
  margin-top: 0.5rem;
}
.copy-resource__document-scroller {
  width: 60rem;
}

.checkbox-wrapper {
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
}
.checkbox-wrapper__row {
  display: grid;
  grid-template-columns: 12rem 5rem 7rem;
  font-size: 1.2rem;
}
.checkbox-wrapper__row--title {
  grid-area: span 1;
}
.checkbox-wrapper__row--cover {
  grid-area: span 2;
}
.checkbox-wrapper__row--inside {
  grid-area: span 3;
  display: flex;
  justify-content: flex-end;
}

.choose-pages-wrapper {
  display: grid;
  grid-template-columns: 14rem 10rem 11rem auto;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
.choose-pages-wrapper__cover-inside-pages {
  display: flex;
  justify-content: flex-end;
}
.choose-pages-wrapper__cover-inside-pages--title {
  margin-right: 1.2rem;
}
.choose-pages-wrapper__all-pages {
  display: flex;
  flex-direction: column;
  border-right: 0.1rem solid #e7e7e7;
  align-items: flex-end;
  padding-right: 0.5rem;
}
.choose-pages-wrapper__all-pages--btn {
  padding: 0 !important;
  color: #2f477c;
  font-weight: bold;
}
.choose-pages-wrapper__pages-scroller {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0.5rem;
}