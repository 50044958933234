/*Screen width*/
.error-list {
  font-size: 1.2rem;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.error-list__table {
  display: grid;
  grid-template-columns: 12rem 12rem 50rem;
  padding-bottom: 1rem;
}
.error-list__table span {
  padding: 0.5rem 1rem;
}
.error-list__table--contents {
  display: contents;
}
.error-list__table--striped span {
  background-color: #f0f5f6;
}
.error-list__error, .error-list__warning, .error-list__info {
  padding: 1rem 0 0.5rem;
}
.error-list__error--text, .error-list__warning--text, .error-list__info--text {
  padding-left: 0.5rem;
}
.error-list__error {
  color: #c13939;
}
.error-list__warning {
  color: #ca7c00;
}
.error-list__info {
  color: #3b424a;
}