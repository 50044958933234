/*Screen width*/
.health-sidebar {
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: #ffffff;
}
.health-sidebar__expand-kpis {
  margin-top: 1rem;
  float: right;
}
.health-sidebar__title {
  font-size: 1.4rem;
}
.health-sidebar__title-row {
  display: flex;
  margin: 0.5rem 0;
}
.health-sidebar__title-row h4 {
  width: 7rem;
}
.health-sidebar__title-row h4:first-child {
  margin-left: 0.5rem;
}
.health-sidebar__message {
  margin-bottom: 0.5rem;
}
.health-sidebar__details {
  width: 17rem;
  padding-top: 1rem;
  text-align: center;
}
.health-sidebar__details-row {
  display: flex;
}
.health-sidebar__details-column {
  width: 7rem;
  padding: 0.2rem 1rem;
}
.health-sidebar__details-column .fa-arrow-up {
  color: #398203;
}
.health-sidebar__details-column .fa-arrow-down {
  color: #d7534e;
}

.summary-container {
  display: flex;
}
.summary-container__promo-metric-item {
  width: 6.5rem;
  white-space: nowrap;
  margin-right: 1rem;
}
.summary-container__drop-down-item {
  flex: 1 0 0;
}

.promo-metric__drop-down {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}