/*Screen width*/
.disabled-activator {
  opacity: 0.1;
  pointer-events: none;
}

.dialog-card {
  border-left: 0.4rem solid #2f477c;
  border-radius: 0;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  min-width: 40rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dialog-card--error {
  border-left: 0.4rem solid #c13939;
}
.dialog-card--warning {
  border-left: 0.4rem solid #ef6c00;
}
.dialog-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px solid #e7e7e7;
}
.dialog-card__title {
  font-family: "Source Sans Pro";
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  color: #37424a;
}
.dialog-card__icon {
  color: #2f477c !important;
  fill: currentColor;
}
.dialog-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 80vh;
  overflow: auto;
}
.dialog-card__footer {
  padding: 1rem;
  align-items: center;
  height: 5.5rem;
  min-height: 5.5rem;
  border-top: 1px solid #e7e7e7;
}
.dialog-card__footer > * > * {
  margin-left: 1rem;
}
.dialog-card__mandatory {
  margin-right: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.dialog-card__mandatory .asterisk {
  margin-right: 0.3rem;
  font-size: 1.8rem;
  line-height: 2rem;
}
.dialog-card .buttons {
  width: 100%;
  text-align: right;
  padding: 2rem;
}
.dialog-card .buttons__right {
  float: right;
}