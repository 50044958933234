/*Screen width*/
.advertisement-fees {
  display: grid;
}
.advertisement-fees__header {
  border-bottom: 0.1rem solid #c2c2c2;
  padding-left: 2rem;
}
.advertisement-fees__header span {
  margin-left: 3.7rem;
}
.advertisement-fees__item, .advertisement-fees__expanded-wrapper {
  display: contents;
}
.advertisement-fees__item > div, .advertisement-fees__expanded-wrapper > div {
  padding: 0.5rem 0.5rem 0 0;
}
.advertisement-fees__item > div::v-deep .rtls-select input, .advertisement-fees__expanded-wrapper > div::v-deep .rtls-select input {
  padding-left: 0.5rem;
}
.advertisement-fees__rate-card {
  grid-column: 1;
}
.advertisement-fees__new-item {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
}
.advertisement-fees__total-label {
  display: flex;
  padding: 0.5rem;
  justify-content: flex-end;
}
.advertisement-fees__action-btns {
  display: flex;
  justify-content: space-around;
}
.advertisement-fees__apply-btn ::v-deep.simple-btn {
  height: 2.8rem !important;
}
.advertisement-fees__delete-btn {
  display: flex;
  justify-content: flex-end;
}
.advertisement-fees__total {
  padding-top: 1.2rem;
  padding-right: 0.5rem;
  margin-left: auto;
}
.advertisement-fees__total-label {
  padding-top: 1rem;
  padding-right: 0.5rem;
}
.advertisement-fees__total-value {
  border-top: 0.1rem solid #000000;
  text-align: right;
  padding-right: 0.5rem;
  width: 8.2rem;
}
.advertisement-fees__amount {
  width: 8.2rem;
}
.advertisement-fees__reason {
  margin-left: 1rem;
  width: 15rem;
}
.advertisement-fees__amount-area {
  display: flex;
}
.advertisement-fees__revert-btn {
  background-color: #2f477c;
  height: 2.9rem;
}