/*Screen width*/
.bookmarks-viewer {
  padding-left: 0.5rem;
}
.bookmarks-viewer .bookmarks-grid {
  display: grid;
  grid-template-columns: 20rem 20rem 20rem 10rem 5rem 5rem 5rem;
}
.bookmarks-viewer__top {
  margin-bottom: 1.5rem;
}
.bookmarks-viewer__header {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #37424a;
  background-color: #ffffff;
  padding-left: 1rem;
  padding-bottom: 0.6rem;
  border-bottom: #e7e7e7 0.1rem solid;
}
.bookmarks-viewer__header.last {
  grid-column-end: span 3;
  padding-right: 1.5rem;
}
.bookmarks-viewer__icons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  height: 100%;
}
.bookmarks-viewer__new-bookmark {
  grid-column: 1/span 6;
  border-top: #e7e7e7 0.1rem solid;
  padding: 1rem 0;
}
.bookmarks-viewer .filler {
  grid-column-start: 1;
  grid-column-end: -1;
  margin-bottom: 1.5rem;
}
.bookmarks-viewer__buttons {
  grid-column-start: 1;
  grid-column-end: -1;
  background: #ffffff;
  padding-bottom: 1rem;
  text-align: right;
  padding-right: 2rem;
}
.bookmarks-viewer__buttons .cancel-button {
  color: #2f477c;
  font-weight: 600;
}
.bookmarks-viewer__author-avatar {
  background-color: #37424a;
  font-size: 1rem;
  font-weight: 400;
}
.bookmarks-viewer ::v-deep .vuex-form-viewer__grid-cell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.6rem 1rem;
  background-color: #ffffff;
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.2rem;
  color: #37424a;
  letter-spacing: 0;
  height: 100%;
}
.bookmarks-viewer ::v-deep .vuex-form-viewer__grid-cell:hover {
  cursor: pointer;
}
.bookmarks-viewer ::v-deep .text-bold {
  font-weight: 600;
}
.bookmarks-viewer ::v-deep .v-input--is-disabled .v-input__control > .v-input__slot:before {
  border-image: none !important;
  border: none !important;
}
.bookmarks-viewer ::v-deep .v-input--is-disabled .v-input__append-inner {
  display: none;
}
.bookmarks-viewer ::v-deep .vuex-form-viewer__edit form > div {
  margin: 0 !important;
}
.bookmarks-viewer ::v-deep .vuex-from-grid > div {
  background: #fff;
  padding: 1rem 1rem 0.7rem;
  height: 100%;
}
.bookmarks-viewer ::v-deep .vuex-from-grid .rtls-select-container {
  padding-top: 0.6rem;
}