/*Screen width*/
.supplier-commitments-form {
  display: contents;
}
.supplier-commitments-form__form {
  display: contents;
}
.supplier-commitments-form__grid-cell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.6rem 1rem;
  background-color: #ffffff;
  border-top: #c2c2c2 0.1rem solid;
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.2rem;
  color: #37424a;
  letter-spacing: 0;
}
.supplier-commitments-form__grid-cell::v-deep .theme--light.v-label {
  color: #37424a;
}
.supplier-commitments-form__grid-cell::v-deep .error--text {
  font-weight: 400;
}
.supplier-commitments-form .supplier-commitments-form__grid-cell:first-child {
  font-weight: 600;
}
.supplier-commitments-form__radio-group::v-deep .v-input--selection-controls {
  margin: 0;
  padding: 0;
}
.supplier-commitments-form__radio-group::v-deep .v-input--selection-controls__input {
  margin-right: 0;
  width: 1.8rem;
}
.supplier-commitments-form__radio-group::v-deep .v-input--selection-controls__input .v-icon.v-icon {
  font-size: 1.4rem;
}
.supplier-commitments-form__expanded-area {
  padding: 0.6rem 1rem;
  background-color: #ffffff;
  grid-column: 1/last-column;
}
.supplier-commitments-form__expanded-component {
  display: contents;
}