/*Screen width*/
.promo-planner {
  height: calc(100% - 6rem);
  background-color: #ffffff;
  position: relative;
}
.promo-planner__sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 22rem;
  height: 100%;
  z-index: 90;
}
.promo-planner__planner-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.promo-planner__campaigns-list {
  margin-right: 22rem;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
.promo-planner__campaigns-list--unscrollable-x {
  margin-right: 22rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.promo-planner__scenarios-viewer {
  flex-grow: 1;
  margin-bottom: 3rem;
  background-color: #f5f5f5;
}
.promo-planner__weekly-planner {
  height: calc(100vh - 17.4rem);
}