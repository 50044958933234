/*Screen width*/
.supply-grid {
  width: 100%;
  height: 75rem;
  border-bottom: solid;
  padding-bottom: 3.5rem;
  border-bottom-width: 0.75rem !important;
  border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;
}
.supply-grid ::v-deep .currency-cell-rewrite,
.supply-grid ::v-deep .percentage-cell-rewrite {
  margin-top: 0.5rem;
  height: 2rem;
}
.supply-grid ::v-deep .currency-cell-rewrite .v-input__slot,
.supply-grid ::v-deep .percentage-cell-rewrite .v-input__slot {
  height: 2rem;
}
.supply-grid ::v-deep .currency-cell-rewrite .vuex-currency-input__currency-symbol,
.supply-grid ::v-deep .currency-cell-rewrite .vuex-percentage-input__percentage-symbol,
.supply-grid ::v-deep .percentage-cell-rewrite .vuex-currency-input__currency-symbol,
.supply-grid ::v-deep .percentage-cell-rewrite .vuex-percentage-input__percentage-symbol {
  background: transparent;
  color: #000000;
  height: 2rem;
}
.supply-grid ::v-deep .currency-cell-rewrite .v-text-field__details,
.supply-grid ::v-deep .percentage-cell-rewrite .v-text-field__details {
  position: absolute;
  z-index: 9;
  bottom: -0.8rem;
  left: 0.8rem;
  display: none;
}
.supply-grid ::v-deep .currency-cell-rewrite .v-text-field__details {
  left: -4rem;
}
.supply-grid ::v-deep .flex-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.supply-grid ::v-deep .ag-cell {
  line-height: 3rem;
  background: #ffffff;
}
.supply-grid ::v-deep .ag-cell .ag-cell-wrapper {
  display: block;
}
.supply-grid ::v-deep .cell-default {
  background: #ffffff;
}
.supply-grid ::v-deep .cell-grey {
  background: #eff5f6;
}
.supply-grid ::v-deep .cell-white {
  background: #ffffff;
}
.supply-grid ::v-deep .case-size-cell {
  border-left: 0.1rem solid #9b9b9b;
  border-right: 0.1rem solid #c2c2c2;
}
.supply-grid ::v-deep .text-right {
  text-align: right;
  padding-right: 0.8rem;
}
.supply-grid ::v-deep .hide-value .ag-cell-value {
  display: none;
}
.supply-grid__title {
  padding: 1rem;
  font-weight: bold;
}
.supply-grid__errors {
  padding: 1rem;
}

.product-case-size {
  border-left: 0.1rem solid;
}