/*Screen width*/
.nomination-matrix {
  display: grid;
  grid-auto-flow: column;
}
.nomination-matrix__label {
  font-size: 1.2rem;
  font-weight: 600;
  align-self: flex-end;
  padding: 0.6rem 0 1.2rem;
  min-width: 10rem;
}
.nomination-matrix .label-stores {
  text-align: right;
  padding-right: 1rem;
}
.nomination-matrix__header {
  padding-left: 0.25rem;
  grid-column-start: 2;
  width: 25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nomination-matrix__sidebar-wrapper {
  grid-column: 1;
  grid-row: span 1;
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nomination-matrix__stores {
  border-right: 1px solid #9b9b9b;
  padding: 0.3rem 0.3rem 0.3rem 0;
}
.nomination-matrix__sidebar {
  padding: 0.3rem 3rem 0.3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nomination-matrix__sidebar--header {
  display: flex;
  flex-direction: row;
  grid-row: span 3;
  grid-column: span 1;
  justify-content: space-between;
  border-right: 1px solid #9b9b9b;
  border-bottom: 1px solid #dddddd;
}
.nomination-matrix__sidebar--footer {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #9b9b9b;
}
.nomination-matrix__sidebar--dp-header {
  padding: 1rem 1rem 1rem 4.5rem;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #9b9b9b;
}
.nomination-matrix__sidebar--dp {
  margin-left: 4.5rem;
  padding: 0.5rem 1rem 0.5rem 0;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #9b9b9b;
}
.nomination-matrix__sidebar--dp div {
  padding: 0.5rem 0;
}
.nomination-matrix__sidebar .v-input--checkbox {
  width: 1rem;
}
.nomination-matrix__emptyCell, .nomination-matrix__emptyCell--top-bordered, .nomination-matrix__emptyCell--red, .nomination-matrix__emptyCell--header {
  grid-row: span 1;
  grid-column: span 1;
  min-height: 3rem;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.nomination-matrix__emptyCell--header {
  grid-row-start: 3;
}
.nomination-matrix__emptyCell--red {
  background-color: red;
}
.nomination-matrix__emptyCell--top-bordered {
  border-top: 1px solid #dddddd;
}
.nomination-matrix--loop-wrapper {
  display: contents;
}
.nomination-matrix__subType {
  grid-row: span 1;
  grid-column: span 1;
  grid-row-start: 4;
  grid-row-end: -2;
  border-right: 1px solid #dddddd;
  padding: 1rem;
}
.nomination-matrix__subType--add-resource {
  background: #f5f5f5;
}
.nomination-matrix__add-resource {
  grid-row: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 0;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background: #f5f5f5;
}
.nomination-matrix__add-resource--cell {
  grid-row: span 1;
  grid-column: span 1;
  background: #f5f5f5;
  border-right: 1px solid #dddddd;
}
.nomination-matrix__add-resource--filler {
  grid-row: span 1;
  grid-column: span 1;
  grid-row-start: 4;
  grid-row-end: -2;
  background: #f5f5f5;
  border-right: 1px solid #dddddd;
  padding: 1rem;
}
.nomination-matrix__add-resource--filler .subtype__button {
  background: #f5f5f5;
  color: #2f477c;
  margin-left: 0.5rem;
}
.nomination-matrix__add-resource--footer {
  grid-row: span 1;
  grid-column: span 1;
  grid-row-start: -2;
  background: #f5f5f5;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}
.nomination-matrix__add-detailed-provision {
  grid-row: span 1;
  grid-column: span 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  padding-left: 0.25rem;
}
.nomination-matrix__delete-resource {
  grid-row: span 1;
  grid-column: span 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  padding-left: 0.25rem;
}
.nomination-matrix__resource-header {
  grid-row: 2;
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0.25rem;
  border-right: 1px solid #dddddd;
  align-items: center;
}
.nomination-matrix__resource-header--unselected {
  background-color: #f5f5f5;
}
.nomination-matrix__state-header {
  grid-row: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 3rem;
  padding: 0.4rem 0.25rem;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.nomination-matrix__icon {
  border-radius: 0.3rem;
  align-self: center;
  justify-self: center;
  color: #2f477c;
  margin-bottom: 0.25rem;
}
.nomination-matrix__cell {
  grid-row: span 1;
  grid-column: span 1;
  font-size: 1rem;
  border-right: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nomination-matrix__cell--highlighted {
  background: #cfe4f1;
}
.nomination-matrix__cell .v-input--checkbox {
  width: 1rem;
}
.nomination-matrix__disabledResource {
  grid-column: span 1;
  grid-row-start: 4;
  grid-row-end: -1;
  border-right: 1px solid #dddddd;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
}
.nomination-matrix__detailed-provisions {
  grid-row: span 1;
  grid-column: span 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding-left: 0.25rem;
}
.nomination-matrix__detailed-provisions .v-input {
  margin: 0;
  padding: 0;
}