/*Screen width*/
.funding-viewer-grid-container {
  width: 100%;
  max-height: 75rem;
  padding-top: 1.5rem;
  border-bottom: solid;
  border-bottom-width: 0.75rem !important;
  border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;
  overflow-y: auto;
}

::v-deep .no-background {
  background: transparent !important;
}
::v-deep .summary-header.volume-funding__header {
  border-left: 0.3rem solid #8b46c1;
}
::v-deep .summary-header.lump-funding__header {
  border-left: 0.3rem solid #76b230;
}
::v-deep .summary-header.variable-funding__header {
  border-left: 0.3rem solid #ca7c00;
}
::v-deep .lump-funding-valid-row {
  background: #dcead3 !important;
  border-top: #398203 0.2rem solid;
  border-radius: 0 0 0 0 !important;
}
::v-deep .lump-funding-invalid-row {
  background: #fcedef !important;
  border-top: #d7534e 0.2rem solid;
  border-radius: 0 0 0 0 !important;
}
::v-deep .ag-floating-bottom .ag-cell.total-cell {
  background-color: #d3dee7 !important;
}
::v-deep .negative-unit-funding {
  color: #c13939 !important;
}
::v-deep .funding-information--calculated__buying-price-greater-than-cost .rtls-text-field, ::v-deep .funding-information--calculated__supplier-compensation-greater-than-cost .rtls-text-field, ::v-deep .funding-information--calculated__negative-funding .rtls-text-field {
  color: #c13939 !important;
}