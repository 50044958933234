/*Screen width*/
.sub-campaign {
  background-color: #e8f1f8;
  border-color: #aecadb;
  color: #37424a;
}
.sub-campaign:hover {
  background-color: #bbdeec;
  border-color: #8ba4b7;
  color: #37424a;
  cursor: pointer;
}
.sub-campaign:hover .sub-campaign__scenario-btn::v-deep svg path {
  fill: #2f477c;
}
.sub-campaign--faded {
  background-color: #f4f9fc;
  border-color: #d7e5ee;
  color: #9da2a6;
}
.sub-campaign.sub-campaign--selected .sub-campaign__scenario-btn::v-deep svg path {
  fill: #2f477c;
}
.sub-campaign .sub-campaign__scenario-btn::v-deep svg path {
  fill: #c7cdda;
}
.sub-campaign .sub-campaign__scenario-btn::v-deep:active svg path {
  fill: #000000;
}