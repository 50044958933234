/*Screen width*/
.offer-changes-viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
}
.offer-changes-viewer__actions {
  width: 100%;
  height: 2rem;
  background-color: #ffffff;
}

.offer-changes-viewer::v-deep .ag-header-container,
.offer-changes-viewer::v-deep .ag-header,
.offer-changes-viewer::v-deep .ag-header-viewport {
  height: 4rem !important;
  min-height: 4rem !important;
}
.offer-changes-viewer::v-deep .ag-center-cols-clipper {
  border-top: solid 0.1rem #eff5f6 !important;
}