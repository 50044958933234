/*Screen width*/
.promotion-products-sidebar {
  height: 100%;
}
.promotion-products-sidebar__inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-left: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.promotion-products-sidebar__ppg {
  border-top: 0.1rem solid #aecadb;
  height: 5rem;
}
.promotion-products-sidebar .disabled-color {
  color: #c2c2c2;
  font-weight: 300;
}
.promotion-products-sidebar__filler {
  height: 6.8rem;
}