/*Screen width*/
.copy-btn {
  outline: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.copy-btn--disabled {
  cursor: not-allowed;
}
.copy-btn--middle {
  vertical-align: middle;
}
.copy-btn--top {
  vertical-align: top;
}
.copy-btn--bottom {
  vertical-align: bottom;
}
.copy-btn--border {
  border-radius: 0.4rem;
  background-color: #2f477c;
  padding: 0.3rem 1.6rem;
}
.copy-btn--border .copy-btn__text {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 400 !important;
}
.copy-btn__text {
  color: #2f477c;
  margin-left: 0.5rem;
}