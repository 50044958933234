/*Screen width*/
.promo-resource-tab-section {
  display: grid;
  grid-template-columns: auto 11rem 11rem;
  position: relative;
}
.promo-resource-tab-section__sidebar-header {
  border-top: 1px solid #2f477c;
  display: flex;
  margin-top: 2rem;
  background-color: #d3dee7;
  height: 4rem;
  align-items: center;
  z-index: 93;
  position: relative;
}
.promo-resource-tab-section__sidebar-header--title {
  position: absolute;
  top: -6rem;
  padding: 1.5rem;
  font-size: 1.2rem;
}
.promo-resource-tab-section__sidebar-content {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 93;
  font-weight: 600;
  line-height: 1.5rem;
}
.promo-resource-tab-section__split-without-sidebar {
  min-height: 70rem;
  grid-template-columns: calc(50% - 12rem) 2rem calc(50% - 12rem) 22rem;
}
.promo-resource-tab-section__split-with-sidebar {
  min-height: 70rem;
  grid-template-columns: calc(50% - 12rem) 2rem calc((50% - 12rem) + 22rem);
}
.promo-resource-tab-section__divider {
  padding-left: 1.5rem;
}
.promo-resource-tab-section__container {
  display: flex;
  justify-content: space-between;
}
.promo-resource-tab-section__container--right {
  align-self: center;
  margin-right: 2rem;
  font-size: 1.2rem;
}
.promo-resource-tab-section__container--right strong {
  margin-right: 1rem;
}
.promo-resource-tab-section .disabled-text {
  color: #c2c2c2;
}
.promo-resource-tab-section .text-right {
  margin-right: 1.2rem;
  padding-bottom: 1.2rem;
}

.document-scroller {
  width: 60%;
}

.page-brief {
  padding-top: 1rem;
  padding-left: 1rem;
}

.promo-resource__locked-msg {
  margin-left: 4rem;
  margin-right: 24rem;
}

.horizontal-divider {
  margin: 1rem 0;
}

.margin-top {
  margin-top: 1.2rem;
}

.margin-left {
  margin-left: 0.8rem;
}

.workflow-tasks-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 0.7rem;
  padding-right: 2rem;
  margin-left: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #e3edde;
}

.page-layout-container {
  height: 100%;
  max-height: 70rem;
}

.page-layout-left-column {
  padding-left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}