/*Screen width*/
.allocation-area-grid {
  display: grid;
  grid-template-columns: auto 10rem 8rem 8rem 8rem 8rem;
  font-size: 1.2rem;
  overflow-y: auto;
}
.allocation-area-grid__cell {
  grid-row: span 1;
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0.8rem;
}
.allocation-area-grid__cell--alternate {
  background-color: #f0f5f6;
}
.allocation-area-grid__cell--highlighted {
  background-color: #e3edf5;
}

.search-bar {
  border-top: 1px solid #2f477c;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: baseline;
  background-color: #d3dee7;
}
.search-bar.no-search-field {
  padding-bottom: 2rem;
}
.search-bar__search-field {
  flex: 0 1 24rem;
}

.row-right-align {
  justify-content: flex-end;
}