/*Screen width*/
.task-buttons-dropdown {
  border-bottom: 0.1rem solid #dddddd;
  padding: 0.1rem 1rem 0.1rem;
}
.task-buttons-dropdown__activator-wrapper {
  border-left: 0.1rem solid #c2c2c2;
  border-right: 0.1rem solid #c2c2c2;
}
.task-buttons-dropdown__task {
  display: flex;
  background: #ffffff;
  justify-content: space-around;
  padding: 0.3rem 0;
  cursor: pointer;
}
.task-buttons-dropdown__task:hover {
  background: #e5f0fc;
}
.task-buttons-dropdown::v-deep .promo-light-blue--text {
  color: #00a0e2;
  border-bottom: 0.1rem solid #2f477c;
  margin-bottom: 0.3rem;
}

.v-menu__content {
  z-index: 98 !important;
}