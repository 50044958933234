/*Screen width*/
.funding-changes-viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.funding-changes-viewer__actions {
  width: 100%;
  background-color: #ffffff;
}
.funding-changes-viewer__actions--button {
  float: right;
  margin: 0.5rem;
  color: #2f477c;
}

.funding-changes-viewer::v-deep .ag-header {
  border-bottom: none !important;
}
.funding-changes-viewer::v-deep .ag-header-row {
  border-bottom: solid 0.1rem #eff5f6 !important;
}
.funding-changes-viewer::v-deep .border-left {
  border-left: solid 1px !important;
  border-left-color: #9b9b9b !important;
}
.funding-changes-viewer::v-deep .bold-text {
  font-weight: bold;
}