/*Screen width*/
.inheritance-chips {
  float: left;
  text-transform: uppercase;
}
.inheritance-chips .parent-badge {
  background: #3b80b1 !important;
  color: #ffffff;
}
.inheritance-chips .child-badge {
  background: #bbe6f8 !important;
}

.parent-and-child .child-badge {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.parent-and-child .child-badge ::v-deep .v-chip__content {
  width: 1.7rem !important;
  white-space: nowrap;
  overflow: hidden;
}
.parent-and-child .parent-badge {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.parent-and-child .parent-badge ::v-deep .v-chip__content {
  width: 1.9rem !important;
  white-space: nowrap;
  overflow: hidden;
}