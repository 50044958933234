/*Screen width*/
.scenario-viewer {
  display: grid;
  grid-template-columns: 4rem 20rem 6rem auto 10rem min-content 15rem 15rem 20rem 22.5rem 2.6rem 1rem 5rem 22rem;
  position: relative;
}
.scenario-viewer__data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: center;
  white-space: nowrap;
  padding: 0 1rem;
  min-height: 3.4rem;
  background-color: #ffffff;
  border-top: #9b9b9b 0.2rem solid;
  border-bottom: #d3d3d3 0.1rem solid;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1.1rem;
  color: #4a4a4a;
  letter-spacing: 0;
}
.scenario-viewer__data--first-item {
  border-left: #9b9b9b 0.5rem solid;
}
.scenario-viewer__data:hover {
  cursor: pointer;
}
.scenario-viewer__grid-cell {
  grid-column: span 1;
}
.scenario-viewer__grid-cell--heading {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}
.scenario-viewer__grid-cell--main-content {
  padding-top: 1.2rem;
  background-color: #f5f5f5;
}
.scenario-viewer__grid-cell--sidebar {
  z-index: 93;
  background-color: #ffffff;
}
.scenario-viewer__grid-cell--sidebar .scenario-viewer__data {
  border-bottom: none;
}
.scenario-viewer__promotions-viewer {
  grid-column-start: 1;
  grid-column-end: -1;
}
.scenario-viewer__overlay {
  z-index: 99 !important;
  grid-column-start: 1;
  grid-column-end: -1;
}

.scenario--faded .scenario-viewer__data {
  opacity: 0.65;
}