/*Screen width*/
.summary-header {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.summary-header__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.summary-header__toggle-container {
  display: flex;
  margin-top: 2rem;
}
.summary-header__toggle-container span {
  padding-top: 0.2rem;
  margin-right: 1rem;
}
.summary-header__banner {
  height: 3rem;
  margin: 1rem;
  padding: 0.5rem 1rem;
  background-color: #ea9b66;
  border: 0.1rem solid #ef6c00;
  border-radius: 0.5rem;
  font-weight: normal;
}