/*Screen width*/
.summary-header {
  flex-grow: 1;
  height: 100%;
  display: flex;
  padding: 0 !important;
  flex-direction: column;
  border-left: solid 0.1rem #c2c2c2;
}
.summary-header__title {
  padding: 0 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
}
.summary-header__info {
  padding: 0 1rem;
  margin: 0 0.5rem 0.5rem 0;
}
.summary-header__label {
  white-space: normal;
  min-height: 3.4rem;
}
.summary-header .line1 {
  width: 13rem;
  background-color: #d3dee7;
  padding: 5px;
  margin-bottom: 5px;
}
.summary-header .download-container {
  margin-left: 9rem;
}
.summary-header .download-label {
  font-weight: 400;
  margin-bottom: 0.5rem;
  white-space: initial;
}
.summary-header .btn-separator {
  margin: 0 1rem;
  color: #c2c2c2;
}
.summary-header .disabledRow {
  cursor: no-drop;
}