/*Screen width*/
.banner {
  margin: 1rem;
  background-color: #ea9b66;
  border: 0.1rem solid #ef6c00;
  border-radius: 0.5rem;
  font-weight: normal;
}
.banner__small {
  height: 2rem;
  padding: 0 1rem 0 1rem;
}
.banner__normal {
  height: 3rem;
  padding: 0.5rem 1rem;
}