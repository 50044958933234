/*Screen width*/
.offer-tab__new-group {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}
.offer-tab__changes-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 1rem;
  padding: 1rem;
}
.offer-tab__changes-buttons .v-btn.v-size--default {
  height: 2rem !important;
}

.v-expansion-panels {
  justify-content: left !important;
  display: unset !important;
}

::v-deep .toggle.rtls-toggle.v-item-group.orange-toggle {
  margin-left: 6.6rem;
}
::v-deep .toggle.rtls-toggle.v-item-group.orange-toggle .v-btn {
  border-color: #ef6c00 !important;
}
::v-deep .toggle.rtls-toggle.v-item-group.orange-toggle .v-btn__content {
  color: #ef6c00 !important;
}
::v-deep .toggle.rtls-toggle.v-item-group.orange-toggle .v-item--active {
  background: #ef6c00 !important;
}
::v-deep .toggle.rtls-toggle.v-item-group.orange-toggle .v-item--active .v-btn__content {
  color: #ffffff !important;
}
::v-deep .toggle.rtls-toggle.v-item-group.orange-toggle:hover .v-btn__content {
  color: #ef6c00 !important;
}
::v-deep .toggle.rtls-toggle.v-item-group.orange-toggle:hover .v-item--active .v-btn__content {
  color: #ffffff !important;
}