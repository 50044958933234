.page {
  height: 100%;
  padding: 0 1rem;
  padding-top: 2rem;
}

::v-deep .v-tabs.promo-tabs.theme--light {
  height: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text.transparent {
  flex: 0 1 0;
}

::v-deep .v-window.v-item-group.theme--light.v-tabs-items {
  flex: 1 0 0;
}

::v-deep .v-window__container {
  height: 100%;
}

::v-deep .v-window-item.tab.v-window-item--active {
  height: 100%;
}