/*Screen width*/
.channels-tab {
  padding: 1rem 1.4rem;
  max-width: calc(100vw - 22rem);
}
.channels-tab__form {
  padding: 0 0.8rem;
}
.channels-tab__form::v-deep .channels-stores-expansion-panel {
  border-top: 0.1rem solid #c2c2c2;
}
.channels-tab__form::v-deep .channels-stores-expansion-panel .rtls-expansion-panel-header__label {
  width: 25%;
  color: #2f477c;
}
.channels-tab__form::v-deep .channels-stores-expansion-panel .expand-btn {
  width: 75%;
  text-align: right;
  padding-right: 1rem;
}
.channels-tab__form::v-deep .channels-stores-expansion-panel .expand-btn__icon {
  font-size: 3rem;
}