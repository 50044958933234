/*Screen width*/
.alerts-container {
  height: 100%;
  width: 100%;
  display: grid;
}
.alerts-container__alerts-list {
  height: 100%;
  width: 100%;
  padding: 2rem;
  border-right: 0.1rem solid #dddddd;
  background-color: #ffffff;
}
.alerts-container__alerts-list:nth-last-child(odd) {
  background-color: #f5f5f5;
}
.alerts-container__alerts-list:last-child {
  padding: 0;
  border-right: 0;
}

.alerts-list__label {
  color: #37424a;
  font-weight: normal;
}