/*Screen width*/
.filter-group__header {
  padding-bottom: 0.7rem;
}
.filter-group__content {
  margin-top: 1.2rem;
  width: 100%;
}
.filter-group__filters {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.filter-group__wrapper {
  min-width: 92%;
}
.filter-group__parent-filter {
  margin-bottom: 0.4rem;
}
.filter-group::v-deep .v-chip--select {
  max-width: 12.5rem;
}
.filter-group::v-deep .v-input--selection-controls.v-input {
  margin-top: 0;
}