/*Screen width*/
.scope__group-of-products {
  display: flex;
}
.scope__value {
  padding-left: 1rem;
  width: 8rem;
}
.scope__individual-products {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.scope__products {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}
.scope__products li {
  display: flex;
  flex-direction: row;
  padding-top: 0.3rem;
  height: 3.1rem;
}
.scope__products li:first-child {
  border-top: 0.1rem solid #c2c2c2;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  height: 3.4rem;
}
.scope__products li::v-deep .rtls-select input {
  padding-left: 0.5rem;
}
.scope__product {
  width: 100%;
}
.scope__delete-btn {
  height: 2.8rem;
}
.scope__add-btn-container {
  display: flex;
  padding-top: 1rem;
}
.scope__add-btn-container .error--text {
  padding-left: 1rem;
}