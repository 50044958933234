/*Screen width*/
.supply-percentage-container {
  display: flex;
  flex-direction: column;
}
.supply-percentage-container .ag-percentage-input {
  display: flex;
  width: 100%;
}
.supply-percentage-container .ag-percentage-input .input-container {
  width: 6.5rem;
}
.supply-percentage-container .ag-percentage-input .input-container::v-deep .v-input__slot {
  height: 1.8rem;
}
.supply-percentage-container .ag-percentage-input .preceding-container {
  padding-right: 0.6rem;
  font-size: 1.6rem;
}