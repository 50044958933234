.theme--light.v-icon {
  color: rgba(0, 0, 0, 0.54);
}
.theme--light.v-icon--disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.theme--dark.v-icon {
  color: #FFFFFF;
}
.theme--dark.v-icon--disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}

.v-icon.v-icon {
  align-items: center;
  display: inline-flex;
  -webkit-font-feature-settings: "liga";
          font-feature-settings: "liga";
  font-size: 24px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-icon.v-icon--dense {
  font-size: 20px;
}

.v-icon--right {
  margin-left: 8px;
}
.v-icon--left {
  margin-right: 8px;
}
.v-icon.v-icon.v-icon--link {
  cursor: pointer;
}
.v-icon--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.v-icon--is-component {
  height: 24px;
  width: 24px;
}
.v-icon--svg {
  height: 24px;
  width: 24px;
  fill: currentColor;
}
.v-icon--dense--is-component {
  height: 20px;
}