/*Screen width*/
.sidebar {
  z-index: 90;
}

::v-deep .candidates-list__candidate {
  grid-column: 1/span 1;
  padding: 0.2rem 5.5rem 0.2rem 2.5rem;
}
::v-deep .candidates-list__product {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.promotion-products {
  grid-column: span 9;
  display: grid;
  padding-top: 1rem;
}
.promotion-products__sidebar {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  border-top: 1px solid #aecadb;
  z-index: 99;
  grid-column: 2;
}
.promotion-products__no_products {
  padding: 1rem 5.5rem;
}