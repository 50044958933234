/*Screen width*/
.year-dates ol {
  padding: 0;
}
.year-dates__nav {
  display: flex;
  flex-direction: row;
  align-items: right;
  height: 3rem;
}
.year-dates__month {
  color: #37424a;
  font-family: "Source Sans Pro";
  font-size: 1.2rem;
  letter-spacing: 0;
  margin-top: 0.75rem;
}
.year-dates__week {
  list-style: none;
  display: inline-block;
  color: #2f477c;
  font-family: "Source Sans Pro";
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  width: 17.5%;
  text-align: center;
}
.year-dates__week:hover {
  color: #ffffff;
  border-radius: 0.75rem;
  background-color: #2f477c;
  cursor: pointer;
}
.year-dates__week.highlighted {
  background-color: #cbd1de;
}
.year-dates__week.highlighted.roundedLeft {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.year-dates__week.highlighted.roundedLeft:hover {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.year-dates__week.highlighted.roundedRight {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.year-dates__week.highlighted.roundedRight:hover {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.year-dates__week.highlighted:hover {
  background-color: #2f477c;
  border-radius: 0;
}
.year-dates__week.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.year-dates__divider {
  box-sizing: border-box;
  height: 0.2rem;
  width: 17rem;
  border: 0.1rem solid #e7e7e7;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.year-dates__carousel {
  position: relative;
  width: 100%;
}
.year-dates__carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.4rem;
  font-weight: bold;
}
.year-dates__carousel-button {
  position: absolute;
  top: calc(50% - 1rem);
  z-index: 2;
}
.year-dates__carousel-button--next {
  right: 0;
}
.year-dates__carousel-button--prev {
  left: 0;
}