/*Screen width*/
.simple-btn {
  padding: 0 1.2rem !important;
  height: 2rem !important;
}
.simple-btn-light {
  background-color: #ffffff;
  border: 0.1rem solid #2f477c;
  color: #2f477c;
}
.simple-btn-dark {
  background-color: #2f477c;
  color: #ffffff;
}
.simple-btn-dark:not([disabled=disabled]):hover {
  background-color: #000000 !important;
}
.simple-btn[disabled=disabled] {
  background-color: #c7cdda;
}