/*Screen width*/
.error-dialog__header {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 600;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.error-dialog__header-divider {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 1rem;
}
.error-dialog__container {
  width: 60rem;
  border-left: 0.4rem solid #2f477c;
  font-size: 1.3rem;
}
.error-dialog__formatting-errors {
  height: 5rem;
  padding-left: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.error-dialog__formatting-errors--upload-failed {
  font-weight: 600;
}
.error-dialog__close-button {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
}
.error-dialog__close-button .v-btn {
  margin-left: auto;
}

::v-deep .v-treeview-node__root {
  min-height: 2rem;
}
::v-deep .v-treeview-node__children :nth-child(even) {
  background-color: #e3edf5;
}
::v-deep .v-treeview-node__root {
  padding-left: unset;
}
::v-deep .v-treeview-node__root .v-treeview-node__content {
  margin-left: unset;
}
::v-deep .v-treeview-node__children {
  overflow-y: auto;
  max-height: 15rem;
}
::v-deep .v-treeview-node {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}