/*Screen width*/
.campaign {
  border-color: #6e8b9c;
  border-style: solid;
  border-width: 2px 0 0 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 1px 5px;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 700;
  color: #37424a;
  display: flex;
  position: relative;
  flex-direction: column;
  opacity: 1;
  animation: fade 0.5s linear;
}
.campaign:hover {
  background-color: #bbdeec;
  border-color: #5a6c76;
  cursor: pointer;
}
.campaign .campaign-name {
  line-height: 1.3rem;
  padding-bottom: 1.5rem;
}
.campaign span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.campaign .icons {
  float: right;
}
.campaign .lock-icon {
  float: left;
}
.campaign .eye-icon {
  float: left;
  padding-top: 0.1rem;
}
.campaign .inheritance-chips {
  transform: scale(0.75);
  margin-left: -0.8rem;
}
.campaign .inheritance-chips .v-chip {
  height: 2.1rem;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}