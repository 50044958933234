@charset "UTF-8";
/*Screen width*/
.flex-row {
  display: flex;
  flex-direction: row !important;
}

.alternative-mechanics__select-container {
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
  margin-right: 0.5rem;
}
.alternative-mechanics__select-container ::v-deep .mdi::before {
  font-size: 2.4rem;
}
.alternative-mechanics__select-container ::v-deep .mdi.mdi-checkbox-marked::before {
  content: "";
}
.alternative-mechanics__select-container ::v-deep .mdi.mdi-checkbox-blank-outline {
  transition: none !important;
}
.alternative-mechanics__select-container ::v-deep .mdi.mdi-checkbox-blank-outline::before {
  content: "";
}

.alternative-mechanics__name {
  margin-left: 1rem;
}