.price-weight {
  display: flex;
}
.price-weight__container {
  display: flex;
  align-items: center;
}
.price-weight__container--input {
  width: 4rem;
  margin: 0 0.2rem;
}
.price-weight__container--select {
  width: 5.3rem;
}
.price-weight__container::v-deep .v-text-field__details {
  display: none;
}