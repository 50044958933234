/*Screen width*/
.workflow-resources {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2rem auto;
}
.workflow-resources__icon {
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
  border-left: 1px solid #e7e7e7;
}
.workflow-resources__icon--last {
  border-right: 1px solid #e7e7e7;
}
.workflow-resources__details {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1rem;
}
.workflow-resources__loop-wrapper {
  display: contents;
}