/*Screen width*/
.positive-action-button {
  background-color: #3d8113 !important;
  color: #ffffff;
  padding: 0 1.2rem !important;
  height: 2rem !important;
}
.positive-action-button:hover.v-size--default {
  background-color: #254d0c !important;
}
.positive-action-button.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #cedfc4 !important;
  color: #ffffff !important;
}