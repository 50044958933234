/*Screen width*/
.product-offer-group {
  height: 100%;
}
.product-offer-group__name {
  margin-bottom: 1rem;
  display: flex;
}
.product-offer-group__name--label {
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-right: 1rem;
}
.product-offer-group__name--wrapper {
  padding: 0.5rem 0 0.5rem 2rem;
}
.product-offer-group__name--text ::v-deep .v-input__slot {
  width: 20rem;
  padding-left: 1rem;
}
.product-offer-group__category-selector {
  display: flex;
  height: 4rem;
  background-color: #cbd1de;
  margin-bottom: 1rem;
  padding-left: 3rem;
  padding-top: 0.5rem;
  column-gap: 1rem;
}
.product-offer-group__category-selector--text {
  padding-top: 0.5rem;
}
.product-offer-group__category-selector--type {
  min-width: 10%;
}
.product-offer-group__category-selector--categories {
  margin-top: 0 !important;
  align-self: flex-start;
}
.product-offer-group__panel {
  border-bottom: 1px solid #2f477c;
}
.product-offer-group__panel--header {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: unset;
  padding-bottom: unset;
}
.product-offer-group__panel--header div {
  display: flex;
  justify-content: space-between;
}
.product-offer-group__filter {
  display: flex;
  align-items: baseline;
}
.product-offer-group__filter ::v-deep .v-input--selection-controls {
  margin: 0 2rem;
  padding-top: 0;
}
.product-offer-group__add-bulk {
  margin-left: 0.5rem;
}
.product-offer-group__filter-bar {
  margin-bottom: 1rem !important;
}
.product-offer-group__filter-bar ::v-deep .v-input__slot {
  width: 105rem;
}
.product-offer-group ::v-deep .disabled-row {
  opacity: 0.5;
  cursor: not-allowed;
}
.product-offer-group ::v-deep .v-select__slot {
  height: 2.8rem !important;
  min-width: 25rem;
}
.product-offer-group ::v-deep .ag-header-cell:first-child {
  display: flex !important;
  justify-content: center !important;
}
.product-offer-group ::v-deep .ag-cell .ag-cell-wrapper {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}