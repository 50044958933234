/*Screen width*/
.filter-bar {
  border-top: 1px solid #2f477c;
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: #d3dee7;
}
.filter-bar__grouping {
  display: flex;
  flex: 1;
  align-items: baseline;
}
.filter-bar__grouping > * {
  margin-right: 1rem;
}
.filter-bar__parent-filter {
  margin-right: 1rem;
}
.filter-bar__label {
  color: #37424a;
  line-height: 2.8rem;
  font-size: 1.4rem;
  font-weight: 300;
}
.filter-bar__main-select {
  flex: 0 1 20rem;
}
.filter-bar__cancel-button {
  padding-top: 0.8rem;
  margin-left: 0.8rem;
}
.filter-bar__add-button {
  line-height: 2.8rem;
}
.filter-bar .filter-group__filters::v-deep .v-autocomplete .v-select__slot > input {
  padding-left: 0.5rem;
}