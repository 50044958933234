/*Screen width*/
.page {
  height: calc(100vh - 6.4rem - 4rem);
}
.page__filter-bar {
  padding: 1rem;
  height: 7rem;
}
.page__filter-bar--placeholder {
  border-top: 1px solid #2f477c;
  width: 100%;
  background-color: #d3dee7;
  height: 5.3rem;
}
.page__details-sidebar {
  position: relative;
  width: 22rem;
  height: calc(100% - 7rem);
  float: right;
  z-index: 90;
  background-color: #ffffff;
}
.page__details-sidebar .sidebar-wrapper {
  padding: 1rem;
}
.page__details-sidebar .sidebar-wrapper__heading {
  color: #37424a;
}
.page__content {
  margin-left: 1rem;
  height: calc(100% - 7rem);
  width: calc(100% - 22rem - 1rem);
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
}
.page__content .top {
  flex: 0 1 auto;
  min-height: 50%;
  max-height: 65%;
  width: 100%;
  border-bottom: 0.1rem solid #dddddd;
}
.page__content .top__alerts {
  width: 75%;
  height: 100%;
  float: left;
}
.page__content .top__alerts .alerts-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page__content .top__alerts .alerts-wrapper__heading {
  padding: 1rem;
  color: #37424a;
}
.page__content .top__alerts .alerts-wrapper__content {
  flex: 0 1 auto;
  border-top: 0.1rem solid #dddddd;
  overflow-y: auto;
}
.page__content .top__notifications {
  height: 100%;
  width: 25%;
  float: right;
  border-left: 0.1rem solid #dddddd;
  overflow-y: auto;
}
.page__content .top__notifications .notifications-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page__content .top__notifications .notifications-wrapper__heading {
  padding: 1rem;
  color: #37424a;
}
.page__content .top__notifications .notifications-wrapper__content {
  border-top: 0.1rem solid #dddddd;
  overflow-y: auto;
}
.page__content .bottom {
  flex: 0 1 auto;
  min-height: 35%;
  max-height: 50%;
  width: 100%;
}
.page__content .bottom__last-viewed {
  height: 100%;
}