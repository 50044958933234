/*Screen width*/
.promotion-maintenance {
  min-height: 81rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}
.promotion-maintenance__content {
  flex-grow: 1;
}
.promotion-maintenance__sidebar {
  flex: 0 0 22rem;
  z-index: 90;
}
.promotion-maintenance .promo-tabs__tab {
  display: flex;
  justify-content: space-between;
  pointer-events: auto !important;
}
.promotion-maintenance .non-parkinglot {
  border-bottom: #d3d3d3 0.1rem solid;
}

.footer {
  display: flex;
  align-items: center;
  padding: 2rem;
}
.footer__execution {
  flex: 50%;
  float: left;
  text-align: left;
}
.footer__unsaved {
  text-align: center;
}
.footer__forecast-save-container {
  display: flex;
  align-items: center;
}
.footer__forecast-save-container__spinner {
  margin: 0 1rem;
}