/*Screen width*/
.commitment-matrix {
  display: grid;
  grid-template-rows: 5rem 5rem auto;
  background: #ffffff;
}
.commitment-matrix__key {
  grid-column-start: 1;
  grid-column-end: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1rem;
  background: #ffffff;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 1rem;
}
.commitment-matrix__label {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  line-height: 1.5rem;
}
.commitment-matrix__header {
  grid-row: 1/span 2;
  display: flex;
  padding: 0 0 0.5rem 0.5rem;
  border-bottom: #c2c2c2 0.1rem solid;
  font-weight: 600;
  line-height: 3rem;
  font-size: 1.1rem;
  align-items: flex-end;
}
.commitment-matrix__resources-wrapper {
  display: contents;
}
.commitment-matrix__resource-header {
  padding: 0.6rem 0 0 1rem;
  margin: 0;
}
.commitment-matrix__resource-options {
  display: contents;
}
.commitment-matrix__resource-options--top {
  padding: 0 0.6rem 0 1.2rem;
  border-left: #c2c2c2 0.1rem solid;
  border-bottom: #c2c2c2 0.1rem solid;
  color: #37424a;
  font-weight: 400;
  font-size: 1.2rem;
  grid-row: 2;
}
.commitment-matrix__resource-options--bottom {
  border-left: #c2c2c2 0.1rem solid;
  border-bottom: #c2c2c2 0.1rem solid;
  color: #37424a;
  font-weight: 400;
  font-size: 1.2rem;
  grid-row: 3;
}
.commitment-matrix__resource-options--bottom .bottom-no-of-occurrences {
  padding: 0.2rem 0.4rem;
  height: 2.2rem;
  border-bottom: #c2c2c2 0.1rem solid;
}
.commitment-matrix__resource-options--bottom .bottom-notes {
  height: 9rem;
}
.commitment-matrix__resource-options--bottom::v-deep .v-text-field.rtls-text-field .v-input__slot {
  height: 1.5rem;
}
.commitment-matrix__resource-options--bottom::v-deep .v-textarea {
  height: 100%;
}
.commitment-matrix__resource-options--bottom::v-deep .v-textarea .v-input__slot {
  height: 100%;
  padding-left: 0.5rem;
  padding-top: 0;
  margin: 0;
  border: none;
}
.commitment-matrix__resource-options--bottom::v-deep .v-textarea .v-input__slot textarea {
  height: 100%;
}
.commitment-matrix__resource-options--bottom::v-deep .v-textarea .v-input__slot fieldset {
  border: none;
}
.commitment-matrix__resource-options--bottom::v-deep .v-textarea .v-input__control {
  height: 100%;
  padding-left: 0;
}
.commitment-matrix__resource-options--bottom::v-deep .v-messages.error--text {
  z-index: 1;
  background-color: #ffffff;
  margin-top: 0.5rem;
}
.commitment-matrix__occurrence {
  width: 5rem;
}
.commitment-matrix__note {
  height: 100%;
}
.commitment-matrix__store-groups {
  grid-row: 3;
  grid-column: 1;
  border-bottom: #c2c2c2 0.1rem solid;
  padding: 1rem;
}
.commitment-matrix__value {
  padding-left: 0.6rem;
  padding-top: 0.6rem;
  font-weight: 400;
  font-size: 1.2rem;
  border-bottom: #c2c2c2 0.1rem solid;
  color: #37424a;
}
.commitment-matrix__value::v-deep .v-text-field.rtls-text-field .v-input__slot {
  height: 2.2rem;
}
.commitment-matrix__value::v-deep .vuex-currency-input__currency-symbol {
  height: 2.3rem;
}
.commitment-matrix .align-top {
  align-items: flex-start;
}
.commitment-matrix .border-left {
  border-left: #c2c2c2 0.1rem solid;
}
.commitment-matrix .resource-options--bottom-width {
  width: 9rem;
}
.commitment-matrix__measurement-counts {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.4rem;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}
.commitment-matrix__measurement-counts--executed {
  background: #2f477c;
  color: #ffffff;
}
.commitment-matrix__measurement-counts--planned {
  background: #ffffff;
  color: #2f477c;
  border-color: #2f477c;
  border-style: solid;
  border-width: 0.1rem;
  line-height: 1.3rem;
}