/*Screen width*/
::v-deep.categories-select {
  font-size: 1.2rem;
}
::v-deep.categories-select .select-all {
  display: flex;
  margin: 0 0 0.5rem 1rem;
}
::v-deep.categories-select .select-all .rtls-checkbox {
  min-width: 20rem;
}
::v-deep.categories-select .tree-container {
  max-height: 25rem;
}
::v-deep.categories-select .tree-container.required .list-container .item input[type=checkbox]:before {
  border-color: #ff5252;
}
::v-deep.categories-select .tree-container.required .list-container .item .inner-item {
  color: #ff5252;
}
::v-deep.categories-select .tree-container .list-container .list {
  border: 0.1rem solid #c2c2c2;
  min-width: 20rem;
}
::v-deep.categories-select .tree-container .list-container .list-container .list {
  border-left: none;
}
::v-deep.categories-select .tree-container .list-container .item {
  flex-direction: row-reverse;
  padding: 0 0.5rem;
}
::v-deep.categories-select .tree-container .list-container .item .arrow {
  display: none;
}
::v-deep.categories-select .tree-container .list-container .item.expanded {
  background-color: #ddf2fb;
  color: inherit;
}
::v-deep.categories-select .tree-container .list-container .item .inner-item {
  padding: 0;
}
::v-deep.categories-select .tree-container .list-container .item.partially-selected input[type=checkbox]:before {
  background-color: #2f477c;
}
::v-deep.categories-select .tree-container .list-container .item.partially-selected input[type=checkbox]:after {
  content: "";
  width: 1rem;
  height: 0.9rem;
  position: absolute;
  right: 0.8rem;
  border-bottom: 0.2rem solid white;
}
::v-deep.categories-select .tree-container .list-container .item.partially-selected input[type=checkbox][disabled]:before {
  background-color: inherit;
}
::v-deep.categories-select .tree-container .list-container .item.partially-selected input[type=checkbox][disabled]:after {
  border-color: #bdbdbd;
}
::v-deep.categories-select .tree-container .list-container .item input[type=checkbox] {
  width: 1.6rem;
  height: 1.6rem;
}
::v-deep.categories-select .tree-container .list-container .item input[type=checkbox]:before {
  content: "";
  width: 1.6rem;
  height: 1.6rem;
  border: 0.2rem solid #2f477c;
  display: inline-block;
  border-radius: 0.2rem;
}
::v-deep.categories-select .tree-container .list-container .item input[type=checkbox]:checked:before {
  background-color: #2f477c;
}
::v-deep.categories-select .tree-container .list-container .item input[type=checkbox]:checked:after {
  content: "";
  position: absolute;
  right: 1rem;
  transform: rotate(45deg);
  height: 1.2rem;
  width: 0.6rem;
  border-bottom: 0.2rem solid white;
  border-right: 0.2rem solid white;
}
::v-deep.categories-select .tree-container .list-container .item input[type=checkbox][disabled]:before {
  border-color: #bdbdbd;
}
::v-deep.categories-select .tree-container .list-container .item input[type=checkbox][disabled]:checked:before {
  background-color: #bdbdbd;
}
::v-deep.categories-select .hide-unit-checkboxes > .list-container > .list input[type=checkbox] {
  display: none;
}
::v-deep.categories-select .disabled-reason .list-container .list-container .item.expanded input[type=checkbox][disabled]:before {
  border-color: #ff5252;
}
::v-deep.categories-select .disabled-reason .list-container .list-container .item.expanded input[type=checkbox][disabled]:checked:before {
  background-color: #ff5252;
}
::v-deep.categories-select .disabled-reason-container {
  width: 40rem;
  color: #ff5252;
  margin: 0.5rem 0;
}