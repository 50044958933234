/*Screen width*/
.create-new-btn {
  outline: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.create-new-btn--disabled {
  cursor: not-allowed;
}
.create-new-btn--middle {
  vertical-align: middle;
}
.create-new-btn--top {
  vertical-align: top;
}
.create-new-btn--bottom {
  vertical-align: bottom;
}
.create-new-btn--border {
  border-radius: 0.4rem;
  background-color: #2f477c;
  padding: 0 1.2rem;
  height: 2rem;
}
.create-new-btn--border .create-new-btn__text {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 400 !important;
}
.create-new-btn--border:hover {
  background-color: #000000 !important;
}
.create-new-btn--border:hover .v-icon rect {
  fill: #000000 !important;
}
.create-new-btn__text {
  color: #2f477c;
  margin-left: 0.5rem;
}