/*Screen width*/
.promotions-viewer {
  background-color: #f5f5f5;
  font-size: 1.2rem;
}
.promotions-viewer__grid {
  display: grid;
  grid-template-columns: 8rem repeat(3, auto) fit-content(38rem) repeat(4, auto) repeat(3, calc(7.3333333333rem));
  position: relative;
}
.promotions-viewer__grid-cell {
  grid-column: span 1;
}
.promotions-viewer__grid-cell--sort {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0.8rem;
  cursor: pointer;
}
.promotions-viewer__grid-cell--sort:hover {
  background: #aecadb;
}
.promotions-viewer__grid-cell--heading {
  display: flex;
  align-items: center;
  background-color: #d3dee7;
  border-top: 1px solid #2f477c;
}
.promotions-viewer__grid-cell--heading__unsorted {
  text-indent: 1rem;
}
.promotions-viewer__grid-cell--heading-sorted {
  background-color: #aecadb;
}
.promotions-viewer__grid-cell--main-content {
  padding-top: 2rem;
  background-color: #f5f5f5;
}
.promotions-viewer__grid-cell--sidebar-heading {
  z-index: 93;
}
.promotions-viewer .filter-promotion--promotion-name {
  width: 20rem;
  padding-left: 1.5rem;
}
.promotions-viewer__sidebar-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 22rem;
  height: 100%;
}
.promotions-viewer__create-new-promotion {
  margin-left: 1.5rem;
  color: #2f477c;
}
.promotions-viewer--first-item {
  padding-left: 1.5rem;
}
.promotions-viewer--first-item .promotions-viewer__grid-cell--sort {
  padding-left: 0.5rem;
}
.promotions-viewer__actions {
  display: flex;
  justify-content: space-between;
}
.promotions-viewer__actions__bottom {
  padding-top: 2rem;
}
.promotions-viewer__actions__bottom .clear-filter {
  margin: auto;
}
.promotions-viewer__actions__bottom .clear-filter-btn {
  box-shadow: none;
  margin-left: 1.5rem;
  padding: 0;
}
.promotions-viewer__actions__bottom .clear-filter-btn .mdi-close {
  font-size: 1.8rem;
}
.promotions-viewer__actions__bottom .clear-filter-btn label {
  cursor: pointer;
  color: #2f477c;
  text-decoration-line: underline;
  font-size: 1.4rem;
}
.promotions-viewer__actions .export-btn {
  margin-right: 24rem;
  margin-top: 2rem;
  color: #2f477c;
}
.promotions-viewer__create-promotion, .promotions-viewer__filter-promotion {
  display: flex;
}
.promotions-viewer__create-promotion span, .promotions-viewer__filter-promotion span {
  display: flex;
}
.promotions-viewer__create-promotion label, .promotions-viewer__filter-promotion label {
  align-self: center;
}
.promotions-viewer__create-promotion--top, .promotions-viewer__filter-promotion--top {
  padding-bottom: 2rem;
}
.promotions-viewer__create-promotion--bottom, .promotions-viewer__filter-promotion--bottom {
  padding-top: 2rem;
}
.promotions-viewer__create-promotion--right, .promotions-viewer__filter-promotion--right {
  margin-right: 30rem;
  float: right;
}
.promotions-viewer__create-promotion--left, .promotions-viewer__filter-promotion--left {
  margin-left: 2rem;
  float: left;
}
.promotions-viewer__create-promotion .v-icon, .promotions-viewer__filter-promotion .v-icon {
  color: #2f477c;
}
.promotions-viewer__create-promotion .filter-btn, .promotions-viewer__filter-promotion .filter-btn {
  margin-left: 1rem;
  align-self: center;
  color: #2f477c;
}
.promotions-viewer__create-promotion .mdi-close, .promotions-viewer__filter-promotion .mdi-close {
  position: absolute;
  left: 22rem;
  align-self: center;
}
.promotions-viewer__virtual-container {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  position: absolute;
}
.promotions-viewer__virtual-container div {
  height: 5.8rem;
  display: flex;
  position: relative;
}
.promotions-viewer__ghost-divider {
  margin: 2rem 1.5rem;
}
.promotions-viewer__ghost-header {
  margin-left: 1.5rem;
}