/*Screen width*/
.promotion-allocation-area {
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.promotion-allocation-area__title {
  padding-bottom: 0.5rem;
}
.promotion-allocation-area__grid {
  display: grid;
  grid-template-columns: repeat(7, auto) repeat(2, calc(11rem));
  position: relative;
}
.promotion-allocation-area__data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.75rem;
  padding-left: 1rem;
  min-height: 3.5rem;
  background-color: #ffffff;
  border-top: #aecadb 0.2rem solid;
  border-bottom: #d3d3d3 0.1rem solid;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #4a4a4a;
  letter-spacing: 0;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.05), 0 0.2rem 0 0 rgba(0, 0, 0, 0.05);
}
.promotion-allocation-area__data:hover {
  cursor: pointer;
}
.promotion-allocation-area__border-colour--1 {
  border-top-color: #d7534e;
}
.promotion-allocation-area__border-colour--2 {
  border-top-color: #ea9b66;
}
.promotion-allocation-area__border-colour--3 {
  border-top-color: #93ab26;
}
.promotion-allocation-area__border-colour--4 {
  border-top-color: #3d8113;
}
.promotion-allocation-area__border-colour--5 {
  border-top-color: #c738b4;
}
.promotion-allocation-area__grid-cell--sidebar-heading {
  padding-left: 1rem;
  display: flex;
  z-index: 93;
  background-color: #d3dee7;
  align-items: center;
  border-top: 1px solid #2f477c;
  height: 4.5rem;
}
.promotion-allocation-area__grid-cell--shadow {
  box-shadow: -0.5rem 0 0.5rem -0.5rem rgba(0, 0, 0, 0.05);
}
.promotion-allocation-area__grid-cell--sidebar {
  z-index: 93;
  background-color: #ffffff;
}
.promotion-allocation-area__grid-cell--sidebar .promotion-allocation-area__data {
  border-bottom: none;
  box-shadow: none;
}
.promotion-allocation-area__grid-cell--promotion-content {
  padding-top: 0.5rem;
  background-color: #f5f5f5;
}
.promotion-allocation-area__grid-cell--button .promotion-allocation-area__data {
  padding-top: 0.45rem;
}
.promotion-allocation-area__grid-cell--truncated-content {
  padding-bottom: 0.2rem;
}

.search-bar {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: baseline;
}
.search-bar__label {
  margin-right: 0.75rem;
}

.last-column {
  padding-right: 0.75rem;
}
.last-column .promotion-allocation-area__data {
  padding-top: 0;
  justify-content: center;
}