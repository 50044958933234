/*Screen width*/
.checkbox-dialog-container {
  width: 40rem;
  display: flex;
  padding-top: 1rem;
}
.checkbox-dialog-container ::v-deep .container.units,
.checkbox-dialog-container ::v-deep .container.categories {
  padding: 0;
}
.checkbox-dialog-container ::v-deep .container.units .select-all,
.checkbox-dialog-container ::v-deep .container.categories .select-all {
  padding: 0 0 0.3rem 0.2rem;
}
.checkbox-dialog-container ::v-deep .container.units .checkboxes-list-container,
.checkbox-dialog-container ::v-deep .container.categories .checkboxes-list-container {
  border: 0.1rem solid #c2c2c2;
  padding: 0.75rem 0;
  height: 11rem;
  overflow: auto;
}
.checkbox-dialog-container ::v-deep .container.units .checkboxes-list-container .rtls-checkbox,
.checkbox-dialog-container ::v-deep .container.categories .checkboxes-list-container .rtls-checkbox {
  padding: 0 0.75rem;
}
.checkbox-dialog-container ::v-deep .container.units .checkboxes-list-container .rtls-checkbox .v-input__control,
.checkbox-dialog-container ::v-deep .container.categories .checkboxes-list-container .rtls-checkbox .v-input__control {
  width: 100%;
}
.checkbox-dialog-container ::v-deep .container.units .checkboxes-list-container .rtls-checkbox .v-input__control .v-input__slot,
.checkbox-dialog-container ::v-deep .container.categories .checkboxes-list-container .rtls-checkbox .v-input__control .v-input__slot {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.checkbox-dialog-container ::v-deep .container.units .checkboxes-list-container .rtls-checkbox .v-input__control .v-input__slot .v-input--selection-controls__input,
.checkbox-dialog-container ::v-deep .container.categories .checkboxes-list-container .rtls-checkbox .v-input__control .v-input__slot .v-input--selection-controls__input {
  margin-left: 0.8rem;
  margin-right: 0;
}
.checkbox-dialog-container ::v-deep .container.units .checkboxes-list-container .rtls-checkbox:hover,
.checkbox-dialog-container ::v-deep .container.categories .checkboxes-list-container .rtls-checkbox:hover {
  background: #ddf2fb;
}
.checkbox-dialog-container ::v-deep .container.units .checkboxes-list-container .checkboxes-list__label,
.checkbox-dialog-container ::v-deep .container.categories .checkboxes-list-container .checkboxes-list__label {
  padding-left: 0.75rem;
}