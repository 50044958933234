/*Screen width*/
.funded-promotions-list-wrapper__funded-promotions {
  grid-column-start: 1;
  grid-column-end: -1;
  font-size: 1.3rem;
  line-height: 1.4rem;
  text-align: left;
  color: #37424a;
  padding-left: 1.5rem;
  font-weight: 600;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  border-top: #e7e7e7 0.1rem solid;
  border-bottom: #e7e7e7 0.1rem solid;
  background-color: #ffffff;
}
.funded-promotions-list-wrapper__funded-promotions--no-allocated-promotions {
  border-top: none;
  font-size: 1.2rem;
  font-weight: 500;
}
.funded-promotions-list-wrapper__promotion-group-title {
  padding-right: 1rem;
  text-align: right;
}
.funded-promotions-list-wrapper__funding-value {
  text-align: right;
  padding-right: 1rem;
  border-left: #c2c2c2 0.1rem solid;
}
.funded-promotions-list-wrapper__funding-value--right-border {
  border-right: #c2c2c2 0.1rem solid;
}
.funded-promotions-list-wrapper__remaining-row-filler {
  grid-column: span 5;
  background-color: #ffffff;
}
.funded-promotions-list-wrapper__funded-promotions-list {
  background-color: #ffffff;
  display: flex;
  grid-column: 2/4;
}
.funded-promotions-list-wrapper__single-column {
  background-color: #ffffff;
  grid-column: span 1;
  padding-bottom: 1.5rem;
}
.funded-promotions-list-wrapper__row-divider {
  grid-column-start: 1;
  grid-column-end: -1;
  border-bottom: #e7e7e7 0.1rem solid;
}