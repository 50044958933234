/*Screen width*/
.expand-btn {
  outline: none;
  text-align: center;
}
.expand-btn--disabled {
  cursor: not-allowed;
}
.expand-btn--bg {
  background-color: #2f477c;
  border-radius: 0.3rem;
}
.expand-btn--bg:hover {
  background-color: #000000 !important;
}
.expand-btn--bg .expand-btn__icon {
  color: #ffffff;
}
.expand-btn--horizontal {
  transform: rotate(270deg);
}
.expand-btn__icon {
  vertical-align: middle;
  color: #2f477c;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.6rem;
}