/*Screen width*/
.expand-collapse {
  display: flex;
}
.expand-collapse__btn {
  color: #2f477c;
  text-decoration: underline;
}
.expand-collapse__btn ::v-deep .v-btn__content {
  font-size: 1.4rem;
}
.expand-collapse__btn ::v-deep .v-btn__content .v-icon {
  padding-top: 0.2rem;
  padding-right: 0.4rem;
}