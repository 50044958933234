/*Screen width*/
.week {
  border-right: 0.1rem solid #c2c2c2;
}
.week__heading {
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #37424a;
  background-color: #fcfcfc;
  border-bottom: 0.1rem solid #c2c2c2;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.week__heading.active {
  border-bottom: 0.4rem solid #2f477c;
}
.week__number {
  font-size: 1.4em;
}
.week .summary-kpis {
  width: 100%;
  padding-top: 1rem;
  text-align: start;
}
.week.week--faded .week__heading {
  opacity: 0.65;
}