/*Screen width*/
.copy-campaign {
  font-size: 1.2rem;
}
.copy-campaign__description {
  padding: 1rem 0;
}
.copy-campaign__form-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 2rem;
}
.copy-campaign__form-item--label {
  margin-right: 1rem;
  font-weight: bold;
}
.copy-campaign__form-item--picker {
  width: 13rem;
}
.copy-campaign .border {
  border-bottom: 0.1rem solid #e7e7e7;
}