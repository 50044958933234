/*Screen width*/
.total-spend-viewer-target__grid {
  font-size: 1.2rem;
  border-bottom: 0.1rem solid #c2c2c2;
  display: grid;
  grid-template-columns: auto 15rem 10rem 10rem 45rem;
}
.total-spend-viewer-target__grid .bold-text {
  font-weight: bold;
}
.total-spend-viewer-target__grid > div {
  padding: 1rem;
}
.total-spend-viewer-target__grid .total-year-target {
  display: flex;
  line-height: 1.4rem;
  justify-self: flex-end;
}
.total-spend-viewer-target__grid .total-year-target .primary-target {
  font-size: 1.4rem;
  color: #00a0e2;
  margin-right: 2rem;
}
.total-spend-viewer-target__grid .number-cell {
  text-align: right;
  background-color: #eef5fd;
  border-right: 0.1rem solid #c2c2c2;
}