.margin-right {
  margin-right: 0.7rem;
}

.flex-grow {
  flex: 1 0 0;
}

.flex-shrink {
  flex: 0 1 0;
}

.scenario-sidebar__metrics {
  line-height: 1.8rem;
}
.scenario-sidebar__icons {
  align-items: flex-start;
}
.scenario-sidebar__icons ::v-deep > * {
  padding: 0 0.2rem;
  height: 1.8rem;
}
.scenario-sidebar__expand-btn::v-deep .expand-btn__icon {
  height: 1.8rem;
}