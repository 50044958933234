/*Screen width*/
.exclude-stores-dialog .flex-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activator {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: #2f477c;
  cursor: pointer;
}
.activator:hover {
  background: #00a0e2;
}

.excluded {
  border: 0.1rem solid #2f477c;
  background: #ffffff;
}
.excluded:hover {
  background: #ffffff;
}