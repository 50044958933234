/*Screen width*/
.slots-selector-container {
  display: flex;
  flex-direction: row;
}
.slots-selector-container__select::v-deep .v-select__slot {
  background-color: #f2f3f4;
}
.slots-selector-container__select::v-deep .v-select__slot .v-label {
  padding-left: 1rem;
  padding-right: 0.6rem;
}
.slots-selector-container__select::v-deep .v-select__selections {
  line-height: 1.6rem;
}
.slots-selector-container__select::v-deep .v-select__selections input {
  width: 0;
}
.slots-selector-container__select::v-deep .v-select__selection.v-select__selection--comma {
  padding-left: 1rem !important;
}
.slots-selector-container__select::v-deep .v-select__slot,
.slots-selector-container__select::v-deep .v-input__slot {
  height: 2.2rem !important;
}
.slots-selector-container__no-of-slots {
  font-size: 1.2rem;
  line-height: 2.4rem;
  padding: 0 1rem 0 1rem;
}
.slots-selector-container__label {
  font-size: 1.2rem;
  line-height: 2.4rem;
  padding: 0 1rem 0 1rem;
}
.slots-selector-container__disabled .slots-selector-container__label {
  color: #c2c2c2;
}