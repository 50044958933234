/*Screen width*/
.variable-funding-viewer {
  display: contents;
}
.variable-funding-viewer__icons {
  padding-top: 1rem;
  padding-left: 1rem;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell {
  font-size: 1.2rem;
  font-weight: 400;
  color: #37424a;
  padding: 1rem 0 0.5rem 1rem;
  margin: 0;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell.category .v-select.v-select--chips.v-select--chips--small .v-chip {
  margin: 0.2rem 0.4rem;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell.category .v-select.v-select--chips.v-select--chips--small .v-select__selections {
  min-height: 2.8rem;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell.scope {
  grid-column: span 2;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell.value {
  display: none;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell.sellInPeriod {
  margin-left: 2rem;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell.agreement {
  font-weight: 600;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell:first-child {
  grid-column-start: 1;
  padding-left: 1.5rem;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell.store-groups {
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
  grid-column: 1/span 9;
  display: flex;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell .rtls-select-container {
  display: flex;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell .rtls-select-container__label {
  padding: 0;
  font-weight: 400;
  line-height: 3rem;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell .rtls-select-container .v-input--is-disabled .v-input__control > .v-input__slot:before {
  border-image: none !important;
  border: none !important;
}
.variable-funding-viewer::v-deep .vuex-form-viewer__grid-cell .rtls-select-container .v-input--is-disabled .v-input__append-inner {
  display: none;
}
.variable-funding-viewer::v-deep .v-messages.error--text {
  z-index: 1;
  background-color: #ffffff;
  margin-top: 0.4rem;
}
.variable-funding-viewer__edit-btn {
  padding-left: 1rem;
}
.variable-funding-viewer .buttons {
  grid-column-start: 1;
  grid-column-end: -1;
  background: #ffffff;
  padding-bottom: 1rem;
  text-align: right;
  padding-right: 2rem;
}
.variable-funding-viewer .cancel-button {
  color: #2f477c;
  font-weight: 600;
}