/*Screen width*/
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox .v-input--selection-controls__input {
  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid #2f477c;
  border-radius: 0.3rem;
}
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox .v-input--selection-controls__input svg {
  height: 1.4rem;
  width: 1.4rem;
  bottom: 0.4rem;
  left: 0.4rem;
  position: absolute;
}
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox .v-input--selection-controls__input input {
  z-index: 1;
}
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox .v-input--selection-controls__input input[role=checkbox] {
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox.v-input--is-disabled .v-input--selection-controls__input {
  border-color: #c7cdda;
  background-color: #ffffff;
}
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox.v-input--is-disabled .v-input--selection-controls__input svg path,
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox.v-input--is-disabled .v-input--selection-controls__input svg text {
  fill: #c7cdda;
}
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox.error--text .v-input--selection-controls__input {
  border-color: #ff5252;
}
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox.error--text .v-input--selection-controls__input svg path,
.icon-checkbox-container::v-deep .v-input--checkbox.rtls-checkbox.error--text .v-input--selection-controls__input svg text {
  fill: #ff5252;
}
.icon-checkbox-container::v-deep .v-input--checkbox.v-input--is-label-active .v-input--selection-controls__input {
  background-color: #2f477c;
}
.icon-checkbox-container::v-deep .v-input--checkbox.v-input--is-label-active .v-input--selection-controls__input svg path,
.icon-checkbox-container::v-deep .v-input--checkbox.v-input--is-label-active .v-input--selection-controls__input svg text {
  fill: #ffffff;
}
.icon-checkbox-container::v-deep .v-input--checkbox.v-input--is-label-active.v-input--is-disabled .v-input--selection-controls__input {
  background-color: #c7cdda;
}
.icon-checkbox-container::v-deep .v-input--checkbox.v-input--is-label-active.v-input--is-disabled .v-input--selection-controls__input svg path,
.icon-checkbox-container::v-deep .v-input--checkbox.v-input--is-label-active.v-input--is-disabled .v-input--selection-controls__input svg text {
  fill: #ffffff;
}