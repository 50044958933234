/*Screen width*/
.override-container {
  display: flex;
  flex-direction: column;
}
.override-container:hover .button-container {
  opacity: 1 !important;
}
.override-container.disabled .ag-override-input .input-container::v-deep .rtls-text-field {
  background-color: transparent !important;
}
.override-container .ag-override-input {
  display: flex;
  width: 18rem;
}
.override-container .ag-override-input .button-container {
  display: flex;
  opacity: 0;
  width: 2rem;
}
.override-container .ag-override-input .input-container {
  width: 8rem;
  height: 2.5rem;
}
.override-container .ag-override-input .input-container .input--bold {
  font-weight: bold;
}
.override-container .ag-override-input .input-container .input--blue {
  color: #60a1e1;
}
.override-container .ag-override-input .input-container::v-deep .rtls-text-field {
  width: 8rem;
  text-align: right;
  padding-right: 0.4rem;
  background-color: #ffffff;
}
.override-container .ag-override-input .initial-value {
  display: flex;
  width: 6rem;
  align-items: center;
  justify-content: end;
  font-size: 1.1rem;
}
.override-container .ag-override-input .input--bold {
  font-weight: bold;
}