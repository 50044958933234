/*Screen width*/
.page-navigator {
  border-top: 1px solid #e7e7e7;
  padding: 2rem 4.5rem 2.5rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.page-navigator__notifications-icon {
  padding-left: 3rem;
  padding-top: 0.2rem;
}
.page-navigator__buttons {
  padding-top: 0.2rem;
}
.page-navigator__template-drop-down {
  width: 25rem;
}
.page-navigator__template-drop-down--is-page-resource {
  margin-bottom: 2rem;
}