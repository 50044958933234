/*Screen width*/
.ag-editable-price-input {
  height: 2rem;
}
.ag-editable-price-input .input-container::v-deep .v-input__slot {
  margin-top: 0.8rem;
  height: 2rem;
}
.ag-editable-price-input ::v-deep .v-messages__message {
  display: none;
}