/*Screen width*/
.footer-renderer {
  display: flex;
  flex-direction: row;
}
.footer-renderer .total {
  display: flex;
  flex-direction: row;
  width: 8rem;
  margin: 0 2rem 0 0;
}
.footer-renderer .total::v-deep .vuex-currency-input__currency-symbol {
  background-color: transparent;
  color: #000000;
  height: 2rem;
}
.footer-renderer .total::v-deep .v-input__slot {
  height: 1.8rem;
  background-color: #f2f3f4;
}
.footer-renderer .total::v-deep .v-input__slot input {
  color: #000000;
}
.footer-renderer .valid {
  border-bottom: #398203 0.2rem solid;
}
.footer-renderer .invalid {
  border-bottom: #d7534e 0.2rem solid;
}