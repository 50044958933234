/*Screen width*/
.allocate-destination {
  font-size: 1.2rem;
  font-weight: 600;
}

.allocate-description {
  font-size: 1.2rem;
}

.allocate-past-promotion {
  display: flex;
}
.allocate-past-promotion__radio {
  width: 40%;
}
.allocate-past-promotion__date-range {
  padding-top: 1.5rem;
}
.allocate-past-promotion__overlay {
  z-index: 99 !important;
}

.in-progress__message {
  font-size: 1.3rem;
  color: #37424a;
  margin: 1rem;
}
.in-progress__message .spinner {
  width: 1.4rem;
  height: 1.4rem;
}

::v-deep .v-text-field__slot label {
  padding-left: 1rem;
}
::v-deep .v-input--selection-controls {
  margin-top: 1.3rem;
  margin-bottom: 0.5rem;
}