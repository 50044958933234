/*Screen width*/
.download-centre-btn {
  margin-right: 24rem;
  margin-top: 2rem;
}

.download-centre {
  font-size: 1.2rem;
  width: 70rem;
  height: 40rem;
  overflow: hidden;
  overflow-y: auto;
}
.download-centre__description {
  padding: 1.5rem 0;
}
.download-centre__content {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  min-height: 34rem;
}
.download-centre__filter, .download-centre__data, .download-centre__format {
  flex-basis: 23rem;
  padding: 0 1rem;
}
.download-centre__filter {
  max-width: 23rem;
  background-color: #d3dee7;
}