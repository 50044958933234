/*Screen width*/
.rag-color-and-icon {
  flex-direction: column;
}

.rag-color {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  margin-top: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.icon ::v-deep svg {
  width: 2rem;
  height: 2rem;
}