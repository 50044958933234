/*Screen width*/
.parking-lot-header {
  width: 100%;
  background-color: #ffffff;
  padding: 2rem 0;
}

.parkinglot-grid-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.parkinglot-grid-container__download-btn {
  position: absolute;
  left: 46rem;
  font-size: 1.2rem;
}

::v-deep .promo-tag {
  border-radius: 12px;
  padding: 4px 8px;
  align-items: center;
  background-color: lightgrey;
}

::v-deep .last-header .ag-header-cell-text {
  padding-left: 1rem !important;
}

::v-deep .ag-column-drop-wrapper {
  margin-bottom: 1rem;
}

.allocation-row {
  background-color: #ffffff;
  margin-left: 0px !important;
  width: 100%;
}

.overflow-win ::v-deep .ag-center-cols-viewport {
  overflow-x: hidden;
}

.overflow-default ::v-deep .ag-center-cols-viewport {
  overflow-x: auto;
}

::v-deep .center-cell-items {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}
::v-deep .ag-status-bar-right {
  margin-right: 20rem;
}
::v-deep .status-bar__action--right {
  position: absolute;
  bottom: 1rem;
  border-top: none;
  right: 2rem;
}
::v-deep .status-bar__action--left {
  height: 2rem;
  position: absolute;
  bottom: 1rem;
  border-top: none;
  left: 2rem;
}
::v-deep .promotion-rag-colour {
  margin-top: 1rem !important;
}