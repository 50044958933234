/*Screen width*/
.filter {
  border-top: 1px solid #2f477c;
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: #d3dee7;
}
.filter__label {
  color: #37424a;
  line-height: 2.8rem;
  font-size: 1.4rem;
  font-weight: 300;
  margin-right: 1rem;
}
.filter .date-pair {
  display: flex;
}
.filter .date-pair__date-picker {
  margin-right: 2rem;
}
.filter__grouping {
  display: flex;
  flex: 1;
  align-items: baseline;
}
.filter__grouping > * {
  margin-right: 1rem;
}
.filter__parent-filter {
  margin-right: 1rem;
}
.filter__label {
  color: #37424a;
  line-height: 2.8rem;
  font-size: 1.4rem;
  font-weight: 300;
}
.filter__cancel-button {
  padding-top: 0.8rem;
  margin-left: 0.8rem;
}
.filter__add-button {
  line-height: 2.8rem;
}