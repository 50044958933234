/*Screen width*/
::v-deep .spinner div {
  border-color: white transparent transparent transparent;
}

.weekly-planner-container .weekly-planner-wrapper {
  min-height: 100%;
  position: relative;
  display: inline-block;
  background-color: #fcfcfc;
}
.weekly-planner-container .weekly-planner-wrapper .planner-title {
  display: flex;
  padding: 0.7rem 1.5rem;
  width: calc(100vw - 30.4rem);
  height: 3.6rem;
  background-color: #fcfcfc;
  font-weight: 700;
  font-size: 1.4rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.weekly-planner-container .weekly-planner-wrapper .planner-title__info {
  margin: 0 1.8rem 0 0.7rem;
}
.weekly-planner-container .weekly-planner-wrapper .planner-title__refresh-weekly-metrics {
  margin-left: auto;
  margin-right: 2rem;
  align-self: flex-end;
}
.weekly-planner-container .weekly-planner-wrapper .planner-title__delta {
  padding-right: 0.5rem;
}
.weekly-planner-container .weekly-planner-wrapper .weekly-planner {
  height: 100%;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
}
.weekly-planner-container .weekly-planner-wrapper .weekly-planner--header {
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 3.6rem;
}
.weekly-planner-container .weekly-planner-wrapper .weekly-planner--campaigns {
  grid-auto-rows: 1rem;
}
.weekly-planner-container .weekly-planner-wrapper .weekly-planner--background {
  position: absolute;
  top: 3.6rem;
  left: 0;
  height: calc(100% - 3.6rem);
}
.weekly-planner-container .weekly-planner-wrapper .weekly-planner .week {
  height: 100%;
  width: 100%;
  border-right: 1px solid #c2c2c2;
  background-color: #ffffff;
}
.weekly-planner-container .weekly-planner-wrapper .weekly-planner .week:nth-child(odd) {
  background-color: #f5f5f5;
}