/*Screen width*/
.grouping-renderer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
}
.grouping-renderer .supplier {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.grouping-renderer .supplier__expansion {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  color: #000000;
}
.grouping-renderer .supplier__details {
  width: 24rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
}
.grouping-renderer .supplier__actions {
  z-index: 100;
  margin-left: 1.5rem;
  font-size: 1.2rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #2f477c;
}
.grouping-renderer .supplier__actions .actions__supplier-commitments {
  text-decoration: underline;
  margin: 0 3rem;
}
.grouping-renderer .supplier__actions .actions__agreement-selector {
  text-decoration: underline;
  margin: 0 3rem;
}
.grouping-renderer .product {
  margin-left: 3.5rem;
  max-width: 22rem;
}
.grouping-renderer .product > .truncate-text {
  cursor: default !important;
}

.supplier-commitments__view-all {
  padding: 1rem;
}