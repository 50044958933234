/*Screen width*/
.promo-metric__title {
  font-size: 1.4rem;
  font-weight: bold;
}
.promo-metric__value {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;
}
.promo-metric__efficiency {
  border-width: 0.1rem;
  border-style: solid;
  border-radius: 1rem;
  text-align: center;
  border-color: #9b9b9b;
}
.promo-metric__efficiency--negative {
  border-color: #d7534e;
}
.promo-metric__efficiency--negative i {
  color: #d7534e;
}
.promo-metric__efficiency--positive {
  border-color: #398203;
}
.promo-metric__efficiency--positive i {
  color: #398203;
}