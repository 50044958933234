/*Screen width*/
.promo-toolbar.v-app-bar--fixed {
  background-color: #ffffff;
  z-index: 102;
}

.promo-toolbar.v-app-bar--fixed.warning {
  background-color: #ef6c00 !important;
}

.vertical-divider {
  height: inherit;
  margin: 0 1.4rem;
}

.toolbar-items {
  align-items: center;
}

.nav-link {
  color: #2f477c;
  padding-bottom: 0.5rem !important;
}
.nav-link::v-deep .v-btn__content {
  font-size: 1.4rem;
}
.nav-link::before {
  opacity: 0;
}

.admin-toolbar-item {
  background-color: #d3dee7;
}

.active-link {
  border-bottom: 0.5rem solid #2f477c;
  padding-bottom: 0 !important;
}

.date {
  color: #4a4a4a;
  font-size: 1.2rem;
}
.date .week-of-year {
  margin-right: 0.6rem;
}

.avatar {
  margin-right: 1rem;
}

.name-text {
  color: #4a4a4a;
  font-size: 1.4rem;
}

::v-deep .cog {
  margin-left: 0.8rem;
}

.user-menu {
  box-shadow: none;
}

.v-menu__content--fixed {
  border-radius: 0;
}

.theme--light.v-list {
  border-radius: 0;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}