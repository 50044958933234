/*Screen width*/
.scenarios-viewer {
  padding-bottom: 2rem;
  background-color: #f5f5f5;
  font-size: 1.2rem;
  padding-left: 1.5rem;
}
.scenarios-viewer__heading {
  padding-top: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #4a4a4a;
}