/*Screen width*/
.attributes {
  display: grid;
}
.attributes__header {
  border-bottom: 1px solid #e7e7e7;
  padding: 1rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  color: #37424a;
}
.attributes__loop-wrapper {
  display: contents;
}
.attributes__value {
  padding: 1rem;
  align-self: end;
}
.attributes__firstValue {
  grid-column-start: 1;
}
.attributes__add-button {
  display: flex;
  align-self: start;
  margin: 1rem 2rem;
}