/*Screen width*/
.redis-bull {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: white;
  font-size: 1.4rem;
}
.redis-bull__queue-operations, .redis-bull__bulk-add, .redis-bull__batch-job {
  display: flex;
  column-gap: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}
.redis-bull__queue-operations--submit, .redis-bull__queue-operations--title, .redis-bull__bulk-add--submit, .redis-bull__bulk-add--title, .redis-bull__batch-job--submit, .redis-bull__batch-job--title {
  margin-top: 1rem;
}
.redis-bull__queue-operations--select-queue, .redis-bull__queue-operations--select-operation, .redis-bull__bulk-add--select-queue, .redis-bull__bulk-add--select-operation, .redis-bull__batch-job--select-queue, .redis-bull__batch-job--select-operation {
  min-width: 11%;
  max-width: 100%;
}
.redis-bull__queue-operations--date-range, .redis-bull__bulk-add--date-range, .redis-bull__batch-job--date-range {
  width: 11%;
}
.redis-bull__queue-operations--checkbox, .redis-bull__bulk-add--checkbox, .redis-bull__batch-job--checkbox {
  align-self: center;
  margin-top: 0;
}
.redis-bull__queue-operations--textarea, .redis-bull__bulk-add--textarea, .redis-bull__batch-job--textarea {
  max-width: 50%;
}
.redis-bull__queue-operations--filter, .redis-bull__bulk-add--filter, .redis-bull__batch-job--filter {
  font-size: 1.4rem !important;
  max-width: 20%;
}
.redis-bull__queue-operations--filter ::v-deep .v-input__slot, .redis-bull__bulk-add--filter ::v-deep .v-input__slot, .redis-bull__batch-job--filter ::v-deep .v-input__slot {
  min-height: 2.8rem;
}
.redis-bull__queue-operations--filter ::v-deep .v-text-field__slot, .redis-bull__bulk-add--filter ::v-deep .v-text-field__slot, .redis-bull__batch-job--filter ::v-deep .v-text-field__slot {
  height: 1.8rem;
}
.redis-bull__queue-operations--text, .redis-bull__bulk-add--text, .redis-bull__batch-job--text {
  font-size: 1.4rem !important;
  max-width: 10%;
}
.redis-bull__queue-operations--text ::v-deep .v-input__slot, .redis-bull__bulk-add--text ::v-deep .v-input__slot, .redis-bull__batch-job--text ::v-deep .v-input__slot {
  min-height: 2.8rem;
}
.redis-bull__queue-operations--text ::v-deep .v-text-field__slot, .redis-bull__bulk-add--text ::v-deep .v-text-field__slot, .redis-bull__batch-job--text ::v-deep .v-text-field__slot {
  height: 1.8rem;
}
.redis-bull__queue-message {
  margin-left: 1rem;
  margin-top: 1rem;
}
.redis-bull ::v-deep .v-input--selection-controls {
  margin-top: 0;
}