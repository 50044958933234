/*Screen width*/
.v-footer.footer {
  background-color: #000000;
  z-index: 101;
  color: #ffffff;
  text-decoration-color: #ffffff;
}
.v-footer .footer-link:hover:before {
  opacity: 0;
}
.v-footer .cookie-manager-btn {
  text-decoration: underline;
}

.subheading {
  font-size: 1.25rem;
  color: #ffffff;
  text-decoration-color: #ffffff;
}

.ow-logo {
  padding-top: 5px;
}