/*Screen width*/
.promotion-name-date__text {
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 1rem;
}
.promotion-name-date__text::v-deep .theme--light.v-text-field.v-input--is-disabled .v-input__control .v-input__slot:before {
  border-image: none;
  border-color: inherit;
}
.promotion-name-date__text::v-deep .v-input__slot {
  height: 1.9rem;
}
.promotion-name-date__text::v-deep .v-input--is-disabled input {
  color: #4a4a4a;
  padding-left: 0 !important;
}
.promotion-name-date__text::v-deep .v-text-field__slot input {
  padding-left: 0 !important;
}