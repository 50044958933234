.losenge-date-display {
  font-size: 1.1rem;
}

.compact {
  padding-right: 1rem;
  font-size: 1.2rem;
}

.date_spacer {
  padding-left: 1.5rem;
}